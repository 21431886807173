//@flow
import React from "react"
import {observer} from "mobx-react"
// import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions, Grid} from "@material-ui/core"
import XsButton from "../../global/ui/xsButton/xsButton"
import PatientStore from "../stores/PatientStore"

@observer
export default class FirstLoginDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsInvitePatient"
				open={PatientStore.isOpenFirstLogin}
				onClose={() => PatientStore.closeFirstLogin()}
				onEscapeKeyDown={() => PatientStore.closeFirstLogin()}
				disableBackdropClick={true}
				maxWidth="sm"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-user-unlock fa-2x"></i>
						</div>
						<div className="xs-header-title">Prihlasovacie údaje</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container justify="center">
						<Grid item>Vaše prihlasovacie meno(login) je: {PatientStore.firstLogin}</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times"></i>}
						text="Zavrieť"
						onClick={() => PatientStore.closeFirstLogin()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
