import {ApiCall} from "../helpers/api"

export default {
	loadCodeList(path, method = "GET") {
		return new ApiCall(path, method).call()
	},
	loadTypeRoutes() {
		return new ApiCall(`/discovery/type-routes`, "GET").call()
	},

	/* Aesculap API START */
	loadPreparations(searchRequest = "") {
		return new ApiCall(
			{
				headers: {
					"Access-Control-Allow-Origin": "*",
					Authorization: "Basic c3BpcmVsaWZlOmE1QER4R15FQmNTOUhTNSQ="
				},
				withCredentials: true,
				customURL: `https://aesculap.sk/api/preparations?q=${encodeURIComponent(searchRequest)}`,
				includeToken: false
			},
			"GET"
		)
	}
	/* Aesculap API END */
}
