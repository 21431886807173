import React, {Component} from "react"

import "./xsTableServerFilters.less"
import {Grid, TextField} from "@material-ui/core"

class XsTableServerFilters extends Component {
	constructor(props) {
		super(props)
	}

	tableFilterItems
	tableFiltersJSX
	tableFilters() {
		const {columnDefs} = this.props.config

		this.tableFilterItems = []

		Object.keys(columnDefs).map((column) => {
			const columnDef = columnDefs[column]
			const dbColumnName = columnDef.dbName

			if (isSafe(columnDef.filter) && isSafe(columnDef.filter.renderElement)) {
				// TODO: optimalizacia: tu to uz nebude treba vsetko kontrolovat,
				// bude stacit to vytiahnut z this.props.defaultFilter
				const defaultValue = isSafe(this.props.defaultFilter[dbColumnName])
					? this.props.defaultFilter[dbColumnName]
					: isSafe(columnDef.filter.defaultValue)
					? columnDef.filter.defaultValue
					: ""

				this.tableFilterItems.push(
					<Grid item xs={columnDef.filter.gridWidth} key={column}>
						{// INPUT
						columnDef.filter.renderElement === "input" && (
							<TextField
								InputLabelProps={{
									shrink: true
								}}
								defaultValue={defaultValue}
								className={`filter-${dbColumnName}`}
								autoComplete="off"
								autoFocus={false}
								label={isSafe(columnDef.filterLabel) ? columnDef.filterLabel : columnDef.title}
								onChange={(e) => {
									this.props.onChange({[dbColumnName]: e.target.value})
								}}
							/>
						)}
					</Grid>
				)
			}
		})

		this.tableFiltersJSX = (
			<Grid container direction="row" spacing={8}>
				{this.tableFilterItems}
			</Grid>
		)
	}

	render() {
		this.tableFilters()

		return <div className="xs-table-filters">{this.tableFiltersJSX}</div>
	}
}

export default XsTableServerFilters
