"use strict"

import {action, observable} from "mobx"

import {load} from "recaptcha-v3"

import * as types from "../../global/config/constants"
import {StorageBase} from "../../global/storage/storageEx"
import {USER_DATA} from "../../global/config/constants"
import {getUser, getClientID} from "../../global/helpers/actions"
import {getFilters} from "../../global/helpers/api"
import settings from "../../global/config/settings"
import api from "../actions/api"
import moment from "moment"

import UIStore from "../stores/UIStore"
import RouterStore from "../../global/store/RouterStore"
import GlobalStore from "../../global/store/GlobalStore"
import InfoDialogStore from "../../global/store/InfoDialogStore"
import WarningStore from "../../global/store/WarningStore"

class PatientStore {
	newPasswordToken = ""

	@observable isOpen = false
	@observable htmlContent = null

	@observable isOpenForgotPassword = false

	@observable isOpenFirstLogin = false

	@observable firstLogin = null
	forgotPasswordFormRef = null

	requestChipForm = null
	requestChipFormData = null
	fieldKey = 1

	@observable isOpenChangePassword = false

	@observable selectedDate = moment()
	@observable eventsByDay = []
	@observable patientEventsData = []

	@observable selectedIdx = undefined
	selectedSlot = undefined
	@observable emptySlots = []

	orderFormRef = null

	@action open() {
		api.ziskajPodmienkyPouzivania()
	}

	@action close() {
		this.isOpen = false
	}

	@action patientActionInfo(token) {
		UIStore.isLoading = true
		load(settings.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("patient_action_info").then((recaptchaToken) => {
				api
					.actionInfo(token, recaptchaToken)
					.call()
					.then((res) => {
						if (isSafe(res)) {
							if (res.consumable && !res.require_password) {
								let request = {
									as_login: true
								}

								this.newPassword(request, token)
							}
							if (res.consumable && res.require_password) {
								this.newPasswordToken = token

								UIStore.status = "password"

								UIStore.isLoading = false
							}

							if (!res.consumable) {
								UIStore.status = "login"

								UIStore.isLoading = false
							}
						} else {
							UIStore.isLoading = false
						}
					})
					.catch(() => {
						UIStore.isLoading = false
					})
			})
		})
	}

	@action newPassword(request, token) {
		load(settings.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("patient_action_consume").then((recaptchaToken) => {
				api
					.newPassword(request, token, recaptchaToken)
					.call()
					.then((response) => {
						if (isSafe(response) && isNotEmpty(response.login)) {
							this.firstLogin = response.login
							this.openFirstLogin()
							this.login(response)
						} else {
							UIStore.isLoading = false
						}
					})
					.catch(() => {
						UIStore.isLoading = false
					})
			})
		})
	}

	@action login(request) {
		api
			.login(request)
			.call()
			.then((user) => {
				if (isSafe(user) && isSafe(user.errors)) {
					GlobalStore.setNotificationMessage("Nesprávne meno alebo heslo.")
				} else {
					StorageBase.deleteByKeyQuery(
						(x) =>
							!(
								isSafe(x.type) ||
								x == types.USER_SETTINGS ||
								x == types.LANGUAGE ||
								x == types.DZPDATE ||
								x == types.DOCTOR
							)
					)

					StorageBase.updateByKey(USER_DATA, user)

					UIStore.status = "prescription"
					UIStore.loggedIn = true
				}

				UIStore.isLoading = false
			})
			.catch(() => {
				UIStore.isLoading = false
				GlobalStore.setNotificationMessage("Nesprávne meno alebo heslo.")
			})
	}

	@action clearAfterLogout = () => {
		StorageBase.deleteByKeyQuery(
			(x) =>
				!(isSafe(x.type) || x == types.USER_SETTINGS || x == types.LANGUAGE || x == types.DZPDATE || x == types.DOCTOR)
		)

		UIStore.status = ""
		UIStore.loggedIn = false
		UIStore.isLoading = false
		RouterStore.push("./somPacient")
	}

	@action logout = () => {
		try {
			api
				.logout()
				.call()
				.then(() => {
					this.clearAfterLogout()
				})
		} catch (err) {
			logger(err)
		}
	}

	@action prescDraft(form) {
		UIStore.isLoading = true

		const values = form.values()
		const requests = JSON.parse(values.requests)

		let drugs = []

		requests.forEach((req) => {
			const morning = isNotEmpty(req.morning) ? req.morning : 0
			const lunch = isNotEmpty(req.lunch) ? req.lunch : 0
			const evening = isNotEmpty(req.evening) ? req.evening : 0
			const sleep = isNotEmpty(req.sleep) ? req.sleep : 0

			drugs.push({
				_type: "EHR.API.JSON.Proxy.DraftPrescriptionProduct",
				product: req.drug,
				package_amount: req.package,
				dosage_transcription: req.type === "RBULK" ? `${morning}-${lunch}-${evening}-${sleep}` : null
			})
		})

		api
			.prescDraft({
				_type: "EHR.API.JSON.Proxy.DraftPrescription",
				_ref: false,
				products: drugs,
				personnel_expertise_contract: {
					_id: values.doctor,
					_ref: true,
					_type: "EHR.Data.Entity.PersonnelExpertiseContract"
				},
				patient: {
					_id: values.person,
					_ref: true,
					_type: "Entity.Data.Client"
				}
			})
			.call()
			.then(() => {
				InfoDialogStore.open({
					content: "Vaša požiadavka na predpis bola úspešne odoslaná lekárovi"
				})

				form.reset()
				form.$("doctor").value = values.doctor
				form.$("rememberDoctor").value = values.rememberDoctor

				this.fieldKey = 1
				UIStore.isLoading = false
			})
			.catch(() => {
				UIStore.isLoading = false
				WarningStore.open(
					"Pri spracovaní vašej požiadavky nastala chyba. Odoslanie skúste zopakovať neskôr alebo kontaktujte vášho lekára."
				)
				// GlobalStore.setNotificationMessage("Odoslanie žiadosti bolo neúspešné")
			})
	}

	@action openForgotPassword(login) {
		this.isOpenForgotPassword = true

		if (isNotEmpty(login)) {
			this.forgotPasswordFormRef.$("email").value = login
		}
	}

	@action closeForgotPassword() {
		this.isOpenForgotPassword = false
	}

	@action sendNewPassword(form) {
		api
			.resetPassword(form.$("email").value)
			.call()
			.then(() => {
				this.closeForgotPassword()
				form.reset()
			})
			.catch(() => {
				this.closeForgotPassword()
				form.reset()
			})
	}

	@action changePassword(form) {
		const formValues = form.values()

		let req = {
			password_new: formValues.password_new,
			password_old: formValues.password_old,
			login: getUser().user.login
		}

		api
			.changePassword(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.errors) && res.errors.length > 0) {
					GlobalStore.setNotificationMessage("Nastala neočakávaná chyba")
				} else {
					GlobalStore.setNotificationMessage("Zmena hesla prebehla úspešne")
					this.closeChangePassword()
					form.reset()
				}
			})
			.catch(() => {
				GlobalStore.setNotificationMessage("Nastala neočakávaná chyba")
			})
	}

	@action openChangePassword() {
		this.isOpenChangePassword = true
	}

	@action closeChangePassword() {
		this.isOpenChangePassword = false
	}

	@action closeFirstLogin() {
		this.isOpenFirstLogin = false
	}

	@action openFirstLogin() {
		this.isOpenFirstLogin = true
	}

	@action handleEventsByDay(sDateTime, resourceId) {
		let from = undefined
		let to = undefined

		if (isNotEmpty(resourceId) && moment(sDateTime).diff(moment().startOf("month")) >= 0) {
			if (moment(sDateTime).month() === moment().month() && moment(sDateTime).year() === moment().year()) {
				from = moment().format(settings.DB_DATE_FORMAT)
				to = moment(sDateTime)
					.endOf("month")
					.format(settings.DB_DATE_FORMAT)
			} else {
				from = moment(sDateTime)
					.startOf("month")
					.format(settings.DB_DATE_FORMAT)
				to = moment(sDateTime)
					.endOf("month")
					.format(settings.DB_DATE_FORMAT)
			}

			const eventsByDayRequest = {
				filters: [
					{
						associated_column: "resource_id",
						values: [
							{
								id_value: resourceId
							}
						]
					}
				],
				from: from,
				to: to,
				spare: false
			}

			if (isNotEmpty(from) && isNotEmpty(to)) {
				api
					.getOpenningHour(resourceId)
					.call()
					.then((res) => {
						let openingHours = {}

						res.forEach((day) => {
							if (isSafe(day.opened)) {
								if (day.opened.length === 1) {
									let amFrom = +day.opened[0].from.substring(0, 2)
									let pmTo = +day.opened[0].to.substring(0, 2)

									openingHours[day.day] = {from: amFrom, to: pmTo}
								}

								if (day.opened.length === 2) {
									let amFrom = +day.opened[0].from.substring(0, 2)
									let pmTo = +day.opened[1].to.substring(0, 2)

									openingHours[day.day] = {from: amFrom, to: pmTo}
								}
							}
						})

						api
							.eventsByDay(eventsByDayRequest)
							.call()
							.then((response) => {
								if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
									let colors = []

									response.rows.forEach((row) => {
										if (row.duration > 0) {
											let openingTime = 0
											if (isSafe(openingHours) && isSafe(openingHours[moment(row.date).day()])) {
												let time = openingHours[moment(row.date).day()]

												openingTime = (+time.to - +time.from) * 60 * 60
											}

											let durPercent = 100

											if (openingTime > 0) {
												durPercent = (row.duration / openingTime) * 100
											}

											if (durPercent > 80) {
												colors.push({date: row.date, color: "#921700"})
											} else if (durPercent > 60) {
												colors.push({date: row.date, color: "#c73a0a"})
											} else if (durPercent > 40) {
												colors.push({date: row.date, color: "#d77624"})
											} else if (durPercent > 20) {
												colors.push({date: row.date, color: "#e7a135"})
											} else if (durPercent > 0) {
												colors.push({date: row.date, color: "#f2c545"})
											}
										}
									})

									this.eventsByDay = colors
								}

								from = undefined
								to = undefined
							})
					})
			}
		} else {
			this.eventsByDay = []
		}
	}

	@action handleEventsByDayUnauth(sDateTime, resourceId) {
		let from = undefined
		let to = undefined

		if (isNotEmpty(resourceId) && moment(sDateTime).diff(moment().startOf("month")) >= 0) {
			if (moment(sDateTime).month() === moment().month() && moment(sDateTime).year() === moment().year()) {
				from = moment().format(settings.DB_DATE_FORMAT)
				to = moment(sDateTime)
					.endOf("month")
					.format(settings.DB_DATE_FORMAT)
			} else {
				from = moment(sDateTime)
					.startOf("month")
					.format(settings.DB_DATE_FORMAT)
				to = moment(sDateTime)
					.endOf("month")
					.format(settings.DB_DATE_FORMAT)
			}

			const eventsByDayRequest = {
				filters: [
					{
						associated_column: "resource_id",
						values: [
							{
								id_value: resourceId
							}
						]
					}
				],
				from: from,
				to: to,
				spare: false
			}

			if (isNotEmpty(from) && isNotEmpty(to)) {
				load(settings.SITE_KEY).then((recaptcha) => {
					recaptcha.execute("patient_opening_hours").then((recaptchaToken) => {
						api
							.getOpenningHourUnauth(resourceId, recaptchaToken)
							.call()
							.then((res) => {
								let openingHours = {}

								res.forEach((day) => {
									if (isSafe(day.opened)) {
										if (day.opened.length === 1) {
											let amFrom = +day.opened[0].from.substring(0, 2)
											let pmTo = +day.opened[0].to.substring(0, 2)

											openingHours[day.day] = {from: amFrom, to: pmTo}
										}

										if (day.opened.length === 2) {
											let amFrom = +day.opened[0].from.substring(0, 2)
											let pmTo = +day.opened[1].to.substring(0, 2)

											openingHours[day.day] = {from: amFrom, to: pmTo}
										}
									}
								})

								load(settings.SITE_KEY).then((recaptcha) => {
									recaptcha.execute("patient_events_by_day").then((recToken) => {
										api
											.eventsByDayUnauth(eventsByDayRequest, recToken)
											.call()
											.then((response) => {
												if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
													let colors = []

													response.rows.forEach((row) => {
														if (row.duration > 0) {
															let openingTime = 0
															if (isSafe(openingHours) && isSafe(openingHours[moment(row.date).day()])) {
																let time = openingHours[moment(row.date).day()]

																openingTime = (+time.to - +time.from) * 60 * 60
															}

															let durPercent = 100

															if (openingTime > 0) {
																durPercent = (row.duration / openingTime) * 100
															}

															if (durPercent > 80) {
																colors.push({date: row.date, color: "#921700"})
															} else if (durPercent > 60) {
																colors.push({date: row.date, color: "#c73a0a"})
															} else if (durPercent > 40) {
																colors.push({date: row.date, color: "#d77624"})
															} else if (durPercent > 20) {
																colors.push({date: row.date, color: "#e7a135"})
															} else if (durPercent > 0) {
																colors.push({date: row.date, color: "#f2c545"})
															}
														}
													})

													this.eventsByDay = colors
												}

												from = undefined
												to = undefined
											})
									})
								})
							})
					})
				})
			}
		} else {
			this.eventsByDay = []
		}
	}

	@action patientEvents() {
		const req = {
			time_from: moment()
				.startOf("day")
				.utc(),
			time_to: moment()
				.add(12, "months")
				.utc()
		}

		api
			.patientEvents(getClientID(), req)
			.call()
			.then((res) => (this.patientEventsData = isSafe(res) ? res : []))
	}

	@action loadFirstFreeSlot(providerId, resourceId, serviceId) {
		const req = {
			events: [
				{
					_ref: false,
					_type: "SRVZO.API.REST.Proxy.Event"
				}
			],
			interval_from: this.selectedDate.toISOString(), //this.selectedDateTime.toISOString(),
			primary_resource_id: resourceId,
			next_slots: 1,
			by_patient_portal: true, //TODO: zapnut na true
			_type: "SRVZO.API.REST.Proxy.Order"
		}

		if (isNotEmpty(serviceId)) {
			req.services = [{_ref: false, _type: "SRVZO.API.REST.Proxy.OrderedService", service_id: serviceId}]
			req.events[0].services = [{_ref: false, _type: "SRVZO.API.REST.Proxy.ScheduledService", service_id: serviceId}]
		}

		if (isNotEmpty(providerId) && isNotEmpty(resourceId)) {
			const user = getUser()
			UIStore.isLoading = true

			if (isSafe(user)) {
				api
					.loadSlots(providerId, req)
					.call()
					.then((res) => {
						const freeSlot = res.slots

						if (isNotEmpty(freeSlot) && freeSlot.length) {
							InfoDialogStore.open({
								content: `Najbližší voľný termín je ${moment(freeSlot[0].interval_from).format(
									"DD.MM.YYYY"
								)}. Systém vám automaticky označí v kalendári na výber termínov tento dátum.`
							})
							this.selectedDate = moment(freeSlot[0].interval_from)
						}
						UIStore.isLoading = false
					})
			} else {
				load(settings.SITE_KEY).then((recaptcha) => {
					recaptcha.execute("patient_free_slots").then((recaptchaToken) => {
						api
							.loadSlotsUnauth(providerId, req, recaptchaToken)
							.call()
							.then((res) => {
								const freeSlot = res.slots

								if (isNotEmpty(freeSlot) && freeSlot.length) {
									InfoDialogStore.open({
										content: `Najbližší voľný termín je ${moment(freeSlot[0].interval_from).format(
											"DD.MM.YYYY"
										)}. Systém vám automaticky označí v kalendári na výber termínov tento dátum.`
									})
									this.selectedDate = moment(freeSlot[0].interval_from)
								}
								UIStore.isLoading = false
							})
					})
				})
			}
		}
	}

	@action setSlots(providerId, resourceId, serviceId) {
		const req = {
			events: [
				{
					_ref: false,
					_type: "SRVZO.API.REST.Proxy.Event"
				}
			],
			interval_from: this.selectedDate.toISOString(), //this.selectedDateTime.toISOString(),
			primary_resource_id: resourceId,
			max_slots: 100,
			by_patient_portal: true, //TODO: zapnut na true
			_type: "SRVZO.API.REST.Proxy.Order"
		}

		if (isNotEmpty(serviceId)) {
			req.services = [{_ref: false, _type: "SRVZO.API.REST.Proxy.OrderedService", service_id: serviceId}]
			req.events[0].services = [{_ref: false, _type: "SRVZO.API.REST.Proxy.ScheduledService", service_id: serviceId}]
		}

		if (isNotEmpty(providerId) && isNotEmpty(resourceId)) {
			const user = getUser()

			if (isSafe(user)) {
				api
					.loadSlots(providerId, req)
					.call()
					.then((res) => {
						if (isSafe(res.slots)) {
							this.emptySlots = res.slots
						} else {
							this.emptySlots = []
						}
					})
			} else {
				load(settings.SITE_KEY).then((recaptcha) => {
					recaptcha.execute("patient_free_slots").then((recaptchaToken) => {
						api
							.loadSlotsUnauth(providerId, req, recaptchaToken)
							.call()
							.then((res) => {
								if (isSafe(res.slots)) {
									this.emptySlots = res.slots
								} else {
									this.emptySlots = []
								}
							})
					})
				})
			}
		} else {
			this.emptySlots = []
		}
	}

	@action invertColor = (color, fontColor, isEventEnd = false, inThePast = false, dailyView = false) => {
		if (isNotEmpty(color)) {
			let hex = color.slice(1)

			let r = parseInt(hex.slice(0, 2), 16)
			let g = parseInt(hex.slice(2, 4), 16)
			let b = parseInt(hex.slice(4, 6), 16)

			if (isEventEnd && !inThePast && !dailyView) {
				let borderColor = `rgb(${r}, ${g}, ${b})`
				let backgroundColor = "#ffffff"
				let textColor = `rgb(${r}, ${g}, ${b})`

				if (isSafe(fontColor)) {
					textColor = fontColor
				}

				return {color: textColor, backgroundColor: backgroundColor, borderColor: borderColor, fontWeight: 400}
			} else {
				let borderColor = `rgb(${r}, ${g}, ${b})`
				let backgroundColor = `rgba(${r}, ${g}, ${b}, 0.7)`
				let textColor = "#000000"

				if (isSafe(fontColor)) {
					textColor = fontColor
				} else {
					if (186 > r * 0.299 + g * 0.587 + b * 0.114) {
						textColor = "#ffffff"
					}
				}

				return {color: textColor, backgroundColor: backgroundColor, borderColor: borderColor, fontWeight: 300}
			}
		} else {
			return {color: "white", backgroundColor: "rgba(128, 128, 128, 0.7)", borderColor: "rgba(128, 128, 128)"}
		}
	}

	@action createOrderWithoutLogin(form) {
		const values = form.values()

		if (isSafe(this.selectedSlot)) {
			load(settings.SITE_KEY).then((recaptcha) => {
				recaptcha.execute("patient_set_schedule").then((recaptchaToken) => {
					let req = this.selectedSlot

					const time_utc = moment(this.selectedDate)
						.clone()
						.set("second", 0)
						.utc()

					const duration = this.serviceData.filter((x) => x.code == this.serviceId)[0].duration

					const time_from = time_utc.format()
					const time_to = time_utc
						.clone()
						.add(+duration, "minutes")
						.format()

					req.client = values.fullname
					// ak bez prihlasnia posielat client (meno priezvisko a rodne cislo)

					req.by_patient_portal = true
					req.interval_from = time_from
					req.interval_to = time_to

					req.primary_resource_id = values.resourceId

					// req.note meno - rodne cislo - telefon, bez prihlasenia len telefon tu bude
					let note = []

					if (isNotEmpty(values.fullname)) note.push(values.fullname)
					if (isNotEmpty(values.year)) note.push(values.year)
					if (isNotEmpty(values.mobile)) note.push(values.mobile)
					if (isNotEmpty(values.email)) note.push(values.email)
					if (isNotEmpty(values.note)) note.push(`(${values.note})`)

					req.events = [
						{
							interval: {
								from: time_from,
								to: time_to
							},
							services: [
								{
									_ref: false,
									_type: "IXSApp.API.REST.Proxy.ScheduledService",
									service_id: values.service
								}
							],
							events_in_calendar: [
								{
									resource_id: values.resourceId,
									_type: "IXSApp.API.REST.Proxy.EventInCalendar",
									_ref: false
								}
							],
							note: note.join(", ")
						}
					]

					if (isNotEmpty(values.email)) req.email = values.email
					if (isNotEmpty(values.mobile)) req.mobile = values.mobile

					if (isNotEmpty(values.service))
						req.services = [
							{
								service_id: values.service,
								_type: "SRVZO.API.REST.Proxy.OrderedService",
								_ref: false
							}
						]

					api
						.createEventUnauth([req], recaptchaToken)
						.call()
						.then(() => {
							this.selectedIdx = undefined
							this.setSlots(values.providerId, values.resourceId, values.service)
							InfoDialogStore.open({
								content: `Termín, na ktorý ste sa objednali, bol úspešne odoslaný lekárovi. K lekárovi ${
									values.doctorName
								} ste sa objednali na ${this.selectedDate.format("DD.MM.YYYY")} o ${this.selectedDate.format("HH:mm")}.`
							})
						})
				})
			})
		} else {
			GlobalStore.setNotificationMessage("Je potrebné vybrať voľný termín")
		}
	}

	@action createOrder(form) {
		const values = form.values()

		if (isSafe(this.selectedSlot)) {
			let req = this.selectedSlot

			const time_utc = moment(this.selectedDate)
				.clone()
				.set("second", 0)
				.utc()

			const duration = this.serviceData.filter((x) => x.code == this.serviceId)[0].duration

			const time_from = time_utc.format()
			const time_to = time_utc
				.clone()
				.add(+duration, "minutes")
				.format()

			const clientId = getClientID()

			if (isEmpty(clientId)) {
				req.client = `${values.fullname} - ${values.identifier}`
			} else {
				req.client_id = clientId
			}
			// ak bez prihlasnia posielat client (meno priezvisko a rodne cislo)

			req.interval_from = time_from
			req.interval_to = time_to
			req.by_patient_portal = true

			req.primary_resource_id = values.resourceId

			req.events = [
				{
					interval: {
						from: time_from,
						to: time_to
					},
					services: [
						{
							_ref: false,
							_type: "IXSApp.API.REST.Proxy.ScheduledService",
							service_id: values.service
						}
					],
					events_in_calendar: [
						{
							resource_id: values.resourceId,
							_type: "IXSApp.API.REST.Proxy.EventInCalendar",
							_ref: false
						}
					],
					note: values.note
				}
			]

			if (isNotEmpty(values.service))
				req.services = [
					{
						service_id: values.service,
						_type: "SRVZO.API.REST.Proxy.OrderedService",
						_ref: false
					}
				]

			api
				.createEvent([req])
				.call()
				.then(() => {
					this.selectedIdx = undefined
					this.setSlots(values.providerId, values.resourceId, values.service)
					InfoDialogStore.open({
						content: `Termín, na ktorý ste sa objednali, bol úspešne odoslaný lekárovi. K lekárovi ${
							values.doctorName
						} ste sa objednali na ${this.selectedDate.format("DD.MM.YYYY")} o ${this.selectedDate.format("HH:mm")}.`
					})
				})
		} else {
			GlobalStore.setNotificationMessage("Je potrebné vybrať voľný termín")
		}
	}

	@observable serviceData = []

	@action getServices(resourceId, form, providerId) {
		const user = getUser()

		const req = getFilters([`tag_type_id=Service.Data.ProvidedService:SHOWCLIENT`])

		if (isSafe(user)) {
			api
				.loadServices(resourceId, req)
				.call()
				.then((res) => {
					if (isSafe(res) && res.length > 0) {
						this.setServicesData(res, form, resourceId, providerId)
					} else {
						WarningStore.open(
							"Lekár neumožnil objednanie sa cez pacientský portál. Ak sa chcete k lekárovi objednať, kontaktujte ho telefonicky."
						)
					}
				})
		} else {
			load(settings.SITE_KEY).then((recaptcha) => {
				recaptcha.execute("patient_provided_services").then((recaptchaToken) => {
					api
						.loadServicesUnauth(resourceId, req, recaptchaToken)
						.call()
						.then((res) => {
							if (isSafe(res) && res.length > 0) {
								this.setServicesData(res, form, resourceId, providerId)
							} else {
								WarningStore.open(
									"Lekár neumožnil objednanie sa cez pacientský portál. Ak sa chcete k lekárovi objednať, kontaktujte ho telefonicky."
								)
							}
						})
				})
			})
		}
	}

	setServicesData(res, form, resourceId, providerId) {
		let items = []
		if (isSafe(res) && res.length > 0) {
			res.forEach((service) => {
				let duration = isSafe(service.duration) ? +service.duration / 60 : 0
				items.push({
					_id: service.provided_service_id,
					code: service.provided_service_id,
					code_ext: service.provided_service_id,
					name_ext: service.name_ext,
					duration: duration,
					item_order: isSafe(service.item_order) ? service.item_order : Number.MAX_VALUE,
					color: service.color
				})
			})
		}

		// sort podla item_order v pripade rovnosti podla name_ext
		items = items.sort((a, b) => {
			return (
				(isSafe(a.item_order) ? +a.item_order : Number.MAX_VALUE) -
					(isSafe(b.item_order) ? +b.item_order : Number.MAX_VALUE) ||
				(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
			)
		})

		if (items.length > 0) {
			this.serviceObj = {label: items[0].name_ext, value: items[0].code}
			this.serviceId = items[0].code
			this.loadFirstFreeSlot(providerId, resourceId, items[0].code)
		}

		this.setSlots(providerId, resourceId, items[0].code)

		this.serviceData = items
		form.$("service").value = items[0]._id
	}

	sendMessageToDoctor(form) {
		const req = form.values()

		api
			.sendMessageToDoctor(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					InfoDialogStore.open({
						content: "Vaša správa bola úspešne odoslaná lekárovi."
					})
					RouterStore.push("./somPacient")
				} else {
					WarningStore.open(
						"Správu sa nepodarilo odoslať. Ak sa chcete kontaktovať s lekárom, kontaktujte ho telefonicky."
					)
				}
			})
	}

	sendMessageToDoctorUnauth(form) {
		const req = form.values()
		logger("req", req)

		load(settings.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("send_email_to_personnel").then((recaptchaToken) => {
				api
					.sendMessageToDoctorUnauth(req, recaptchaToken)
					.call()
					.then((res) => {
						if (isSafe(res)) {
							InfoDialogStore.open({
								content: "Vaša správa bola úspešne odoslaná lekárovi."
							})
							RouterStore.push("./somPacient")
						} else {
							WarningStore.open(
								"Správu sa nepodarilo odoslať. Ak sa chcete kontaktovať s lekárom, kontaktujte ho telefonicky."
							)
						}
					})
			})
		})
	}
}

var singleton = new PatientStore()
export default singleton

