import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				email: {
					label: <FormattedMessage id="Common.label.loginName" />
				},
				password: {
					label: <FormattedMessage id="Common.label.password" />
				},
				confirmPassword: {
					label: <FormattedMessage id="Common.label.confirmPassword" />
				},
				agree: {
					label: "Súhlasím s podmienkami používania",
					type: "checkbox",
					value: false
				}
			}
		}
	}
}
