import React, {Component} from "react"
import {observer} from "mobx-react"

import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import classNames from "classnames"
import Popover from "@material-ui/core/Popover"
import DropDownIcon from "@material-ui/icons/ArrowDropDown"
import {withStyles} from "@material-ui/core/styles"

import "./xsMenu.less"

const styles = () => ({
	xslistitem: {
		cursor: "pointer",

		"&:hover": {
			backgroundColor: "#f0f3f8"
		}
	}
})

@observer
class XsMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null,
			text: isSafe(props.default)
				? props.default
				: isSafe(props.items) && props.items.length > 0
				? props.items[0][isSafe(props.param) ? props.param : "name"]
				: "",
			param: isSafe(props.param) ? props.param : "name"
		}
	}

	handleClick = (event) => {
		this.setState({anchorEl: event.currentTarget})
	}

	handleClose = () => {
		this.setState({anchorEl: null})
	}

	render() {
		const {anchorEl} = this.state
		const {className = "menu-button", disabled, icon, buttonStyle} = this.props

		return (
			<div className="xs-menu">
				<Button
					onClick={this.handleClick}
					className={className}
					style={buttonStyle}
					disabled={isSafe(disabled) ? disabled : false}
				>
					{isSafe(icon) ? icon : ""}
					<div className="xs-menu-content">
						{isSafe(this.props.default) ? <span>{this.props.default}</span> : <span>{this.state.text}</span>}
						{!disabled && <DropDownIcon className="xs-menu-icon" />}
					</div>
				</Button>
				<Popover
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
				>
					{(isSafe(this.props.items) && this.props.items.length) > 0 && (
						<List>
							{this.props.items.map((item, idx) => {
								return (
									<ListItem
										key={idx}
										className={classNames(this.props.classes.xslistitem, item.className)}
										onClick={() => {
											this.handleClose()
											if (isSafe(item.onClick)) {
												item.onClick()
											}
											if (isSafe(this.props.onClick)) {
												this.props.onClick(item)
											}
											if (!isSafe(this.props.default)) {
												this.setState({text: item[this.state.param]})
											}
										}}
									>
										{isSafe(item.icon) && item.icon}
										<ListItemText primary={item[this.state.param]} />
									</ListItem>
								)
							})}
						</List>
					)}
				</Popover>
			</div>
		)
	}
}

export default withStyles(styles)(XsMenu)
