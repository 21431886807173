"use strict"

import {observable, action} from "mobx"
import {formDataStateCL} from "../../../global/config/codeLists"

class DataStore {
	//Šablóny pre formuláre
	@observable templateIsOpen = false
	@observable templateIsMounted = false
	templateSpecialType = null
	@observable templateAnchorEl = null
	@observable templates = []
	@observable templateData = null
	@observable templateDataLoaded = false
	@observable templateSourceText = ""
	@observable templatesAll = {}
	templateSourceRange = null
	translateNonInsurer = ""
	deletingTemplate = false
	lastVisitedPatientID = null

	@observable nonPayer = ""
	contactUnknow = ""
	contactUnknowText = ""
	addressUnknow = ""
	addressDesc = ""

	nonInsurerTxt = ""
	addNowTxt = ""
	patientIsNotEUInsuranceTxt = ""
	fixNowTxt = ""
	patientIsEUInsuranceTxt = ""
	patientIsSKInsurer = ""

	//Form Data States
	formDataState = {
		PatientRecordForm: formDataStateCL.EMPTY,
		PatientPrescriptionForm: formDataStateCL.EMPTY,
		PatientRequestForm: formDataStateCL.EMPTY,
		PatientMedicationForm: formDataStateCL.EMPTY,
		PatientOperationForm: formDataStateCL.EMPTY,
		PatientSicknessAbsForm: formDataStateCL.EMPTY,
		PatientAnamnesisForm: formDataStateCL.EMPTY
	}

	// recordDataState = formDataStateCL.EMPTY
	// prescriptionState = formDataStateCL.EMPTY
	requestState = formDataStateCL.EMPTY
	medicationState = formDataStateCL.EMPTY

	multiFormsFromStorage = {}

	//Všetko okolo pacienta - nezabudnite pridať!
	@observable patientDTO = observable.map({
		patient: null,
		patientID: null,
		patientEHR: null,
		insurersDTO: null,
		clientId: null,
		capitationsDTO: null,
		capitations: null,
		contacts: null,
		currContact: null,
		contracts: null,
		addresses: null,
		currAddress: null,
		insurances: null,
		insurer: null,
		insType: null,
		insurerType: null,
		insurerCodeExt: null,
		insurerShortName: null,
		insurerId: null,
		consents: null,
		contactpersons: null,
		alertsChips: null,
		tagsChips: null,
		alergiesChips: null,
		implants: null,
		isNonPayer: false,
		isCapitation: false,
		isEUInsured: false,
		nextVisit: null,
		nextEventId: null,
		nextEventName: null,
		lastVisit: null,
		lastPreventiveExamination: null,
		lastRecord: null,
		diagnosis: null,
		bloodType: null,
		savedForms: []
	})

	//Formuláre Alerty, Alergie a Tagy
	patientAlertsDTOChipForm
	patientAllergiesDTOChipForm
	patientTagDTOChipForm
	alertsChipsForm
	alergiesChipsForm
	tagsChipsForm

	//Zoznam pacientov
	patientsNotFilterData = []
	@observable orderedPatientsData = {}
	@observable orderedPatientsDataArr = []
	@observable patientsData = new Array()
	@observable patientsTagsData = new Array()
	// @observable patientsDiagnoses = new Array()
	patientsFilteredDiagnoses = null
	patientListTableRef = null

	@observable resourcesData = new Array()
	@observable checkedEHR = new Array()
	@observable savedFormObject = null

	calendarFrom = undefined
	calendarTo = undefined
	patientIdReloaded = undefined
	providedServices = {}
	providedServicesNames = {}

	@observable countEvents = 0
	@observable patientDraftIds = []

	@observable nameNewPatient = undefined
	@observable isCreatedFromEvent = false

	//Metóda pre overenie totožnosti pacienta pri nastavovaní jeho EHR do Overview
	@action setPatientEHR(ehrResult) {
		/*if (ehrResult && ehrResult.request && ehrResult.request.filters) {
			const patientFilter = ehrResult.request.filters.find((f) => f.associated_column === "patient_id")
			if (patientFilter) {
				if (
					patientFilter.values &&
					patientFilter.values.length > 0 &&
					this.patientDTO.get("patientID") === patientFilter.values[0].id_value
				) {
					this.patientDTO.set("patientEHR", ehrResult)
				}
			}
    }*/
		if (ehrResult && ehrResult.ixs_request_id === this.patientDTO.get("patientID")) {
			this.patientDTO.set("patientEHR", ehrResult)
		}
	}

	@action openTemplateForm(specialType = null) {
		this.templateSpecialType = specialType
		this.templateIsOpen = true
	}

	@action closeTemplateForm() {
		this.templateSpecialType = null
		this.templateIsOpen = false
	}

	@action editTemplate(target, record, specialType = null) {
		this.templateAnchorEl = target
		this.templateData = record
		this.openTemplateForm(specialType)
	}

	@action insertDataToTemplateForm(form) {
		form.$("name").value = this.templateData.name
		form.$("scope").value = this.templateData.scope === "Pr" ? true : false
		if (this.templateData.type_id == "RINDIVID") {
			form.$("templateStream").value = htmlToPlain(this.templateData.template_stream)
		} else {
			form.$("templateStream").value = this.templateData.template_stream.replace(/\\\"/g, '"') // eslint-disable-line
		}
		this.templateDataLoaded = true
	}

	// searchPatientRequest = {
	//   filters: [],
	//   // row_count_show: 10,
	//   // row_offset: 1
	// }

	@observable search_column_patientListFilter = undefined
	@observable diagnoses_patientListFilter = undefined
	@observable tags_patientListFilter = undefined
	@observable all_patientListFilter = undefined
	@observable orgUnit_patientListFilter = true
	@observable insurer_patientListFilter = undefined

	@action clearCheckedEHR() {
		this.checkedEHR = []
	}

	@action addToCheckedEHR(selected) {
		if (!this.isCheckedEHR(selected)) {
			this.checkedEHR.push(selected)
		}
	}

	@action removeFromCheckedEHR(selected) {
		if (this.isCheckedEHR(selected)) {
			const index = this.checkedEHR.map((a) => a.record_id === selected.record_id).indexOf(true)
			this.checkedEHR.splice(index, 1)
		}
	}

	@action isCheckedEHR(selected) {
		if (selected) {
			return Boolean(this.checkedEHR.find((ehr) => ehr.record_id === selected.record_id))
		}
	}

	@action filterPatients(filter) {
		if (filter === "all") {
			this.patientsData = this.patientsNotFilterData
		} else if (typeof filter === "boolean") {
			this.patientsData = this.patientsNotFilterData.filter((i) => i.is_vip == filter)
		} else if (typeof filter === "number") {
			this.patientsData = this.patientsNotFilterData.filter((i) => i.insurer_id == filter)
		} else {
			this.patientsData = this.patientsNotFilterData.filter((i) => i.gender_id == filter)
		}
	}

	@action setPatientInfo(data) {
		let patient = data.specific_rels.find((sr) => {
			return sr._type === "Entity.Data.Client"
		})
		if (isSafe(patient)) {
			//kontakty
			if (isSafe(patient.contacts)) {
				this.patientDTO.set("contacts", patient.contacts)
			}
			//adresy
			if (isSafe(patient.addresses)) {
				patient.addresses = patient.addresses.filter((x) => isSafe(x.type) && x.type._id === "CA")
				this.patientDTO.set("addresses", patient.addresses)
			}
			//insurance
			if (isSafe(patient.insurances)) {
				let actInsurer = patient.insurances.find((ins) => isSafe(ins.validity) && ins.validity.now)
				if (isSafe(actInsurer)) {
					if (isSafe(actInsurer.type) && actInsurer.type.code !== "GH") {
						this.patientDTO.set("insurerType", actInsurer.type.code_ext)
					} else {
						this.patientDTO.set("insurerType", "")
					}
					if (isSafe(actInsurer.type)) {
						this.patientDTO.set("insType", actInsurer.type.code_ext)
					}
					if (isSafe(actInsurer.insurer)) {
						this.patientDTO.set("insurerCodeExt", actInsurer.insurer.code_ext)
						this.patientDTO.set("insurer", actInsurer.insurer.code_ext + " - " + actInsurer.insurer.name_ext)
						if (actInsurer.type._id == "EU") {
							this.patientDTO.set("isEUInsured", true)
						} else {
							this.patientDTO.set("isEUInsured", false)
						}
					} else {
						this.patientDTO.set("insurer", this.translateNonInsurer)
					}
				} else {
					this.patientDTO.set("insurerType", "")
					this.patientDTO.set("insType", "")
					this.patientDTO.set("insurer", this.translateNonInsurer)
				}
				this.patientDTO.set("insurances", patient.insurances)
			}
		}
	}

	@action getContacts(contacts) {
		this.patientDTO.set("contacts", contacts)

		if (contacts.length > 0) {
			let currContact = contacts.find((x) => x.primary)

			if (isNotSafe(currContact)) {
				currContact = contacts[0]
			}

			if (isNotEmpty(currContact.mobile) || isNotEmpty(currContact.phone) || isNotEmpty(currContact.email)) {
				let contact = isNotEmpty(currContact.mobile)
					? currContact.mobile
					: isNotEmpty(currContact.phone)
					? currContact.phone
					: currContact.email
				this.patientDTO.set("currContact", {contact: contact, contact_type: currContact.contact_type.description})
			} else {
				this.patientDTO.set("currContact", {contact: this.contactUnknow, contact_type: this.contactUnknowText})
			}
		} else {
			this.patientDTO.set("currContact", {contact: this.contactUnknow, contact_type: this.contactUnknowText})
		}
	}

	@action getAddresses(addresses) {
		let addressesPA = addresses.filter((x) => isSafe(x.type) && x.type._id === "PA")
		let addressesCA = addresses.filter((x) => isSafe(x.type) && x.type._id === "CA")

		this.patientDTO.set("addresses", addressesCA)

		if (addressesPA.length > 0) {
			let currAddress = addressesPA[0]

			if (isEmpty(currAddress.full_address)) {
				this.patientDTO.set("currAddress", {address: this.addressUnknow, address_desc: this.addressDesc})
			} else {
				this.patientDTO.set("currAddress", {address: currAddress.full_address, address_desc: this.addressDesc})
			}
		} else {
			this.patientDTO.set("currAddress", {address: this.addressUnknow, address_desc: this.addressDesc})
		}
	}

	@action setSavedFromObject(sf = null) {
		this.savedFormObject = sf
	}

	tempEventRequestId = 0
	acceptRequest = true

	@action getIXSRequestId(id) {
		this.acceptRequest = id == this.tempEventRequestId
	}

	/**
	 * Nastaví formuláru stav podľa vstupných parametrov
	 * @param formName Názov (identifikátor) formulára
	 * @param state Stav
	 */
	setFormDataState(formName, state) {
		if (this.formDataState && this.formDataState[formName]) {
			this.formDataState[formName] = state
			return true
		}
		return false
	}

	/**
	 * Získa stav formulára podľa jeho identifikátora
	 * @param formName Názov (identifikátor) formulára
	 */
	getFormDataState(formName) {
		return this.formDataState[formName]
	}

	/**
	 * Zmení stav na všetkých formulároch na EMPTY
	 */
	clearFormDataState() {
		Object.keys(this.formDataState).forEach((dataState) => {
			if (this.formDataState[dataState]) {
				this.formDataState[dataState] = formDataStateCL.EMPTY
			}
		})
	}

	/**
	 * Vráti či má žiadaný formulár žiadaný stav
	 * @param formName Názov (identifikátor) formulára
	 * @param state Stav
	 */
	checkFormDataState(formName, state) {
		if (formName && state) {
			return this.getFormDataState(formName) === formDataStateCL[state]
		}
		return false
	}
}

var singleton = new DataStore()
export default singleton
