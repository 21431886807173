"use strict"

import {injectIntl} from "react-intl"
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../global/ui/globalUISchemeBindings"
import classnames from "classnames"

import {getDoctor, getClientID} from "../../global/helpers/actions"
import {Grid} from "@material-ui/core"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../global/ui/xsInput/xsInput"

import PatientStore from "../stores/PatientStore"
import GlobalStore from "../../global/store/GlobalStore"
import api from "../actions/api"

@injectIntl
@observer
export default class SendMessageToDoctor extends React.Component {
	constructor(props) {
		super(props)

		window.scrollTo(0, 0)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				PatientStore.sendMessageToDoctor(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					personnel_expertise_contract_id: {
						label: "Napíšte jeho meno, priezvisko, prípadne adresu ambulancie",
						rules: "required",
						value: getDoctor()
					},
					recipient_personnel_id: {
						rules: "required"
					},
					recipient_provider_id: {},
					subject: {
						label: "Predmet správy",
						rules: "required"
					},
					message: {
						label: "Text správy",
						rules: "required"
					},
					sender_patient_id: {value: getClientID()}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		PatientStore.orderFormRef = this.form
	}

	componentDidMount() {
		let scripts = [
			"../public/scripts/jquery.min.js",
			"../public/scripts/popper.min.js",
			"../public/scripts/bootstrap.js",
			"../public/scripts/moment.min.js",
			"../public/scripts/moment-business.js",
			"../public/scripts/aos.js",
			"../public/scripts/clipboard.js",
			"../public/scripts/jquery.fancybox.min.js",
			"../public/scripts/flickity.pkgd.min.js",
			"../public/scripts/ion.rangeSlider.min.js",
			"../public/scripts/isotope.pkgd.min.js",

			"../public/scripts/jarallax.min.js",
			"../public/scripts/jarallax-video.min.js",
			"../public/scripts/jarallax-element.min.js",
			"../public/scripts/jquery.countdown.min.js",
			"../public/scripts/plyr.polyfilled.min.js",
			"../public/scripts/prism.js",
			"../public/scripts/scrollMonitor.js",
			"../public/scripts/smooth-scroll.polyfills.js",
			"../public/scripts/twitterFetcher_min.js",
			"../public/scripts/typed.min.js",
			"../public/scripts/theme.js",
			"../public/scripts/controller.js",

			"../public/scripts/sweetalert.min.js",
			"../public/scripts/maine.js"
		]

		for (let i = 0; i < scripts.length; i++) {
			const script = document.createElement("script")
			script.src = scripts[i]
			script.async = false
			// script.onload = () => document.querySelector("body").classList.add("loaded")

			document.body.appendChild(script)
		}
		document.querySelector("body").classList.add("loaded")
	}

	componentWillUnmount() {
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; i++) {
			scripts[i].parentNode.removeChild(scripts[i])
		}
	}

	loadDoctorData = (request) => {
		return new Promise(function(resolve) {
			api
				.loadDoctor(request)
				.call()
				.then((res) => resolve(res.rows))
		})
	}

	render() {
		return (
			<section className={classnames("bg-primary-3 has-divider text-light")} id="sendMessageToDoctor">
				<div className="container pb-md-0">
					<Grid container>
						<Grid item xs={12}>
							Vyplnením položiek formulára môžete odoslať lekárovi správu alebo otázku. Lekár Vám na ňu bude odpovedať
							správou, ktorá bude doručená do Vašej emailovej schránky, kde následne môžete v prípade potreby pokračovať
							v mailovej komunikácii s lekárom.
						</Grid>
						<Grid item xs={6} className="pt-5">
							<XsAutocomplete
								field={this.form.$("personnel_expertise_contract_id")}
								minLengthForSearch="3"
								clearText
								specialLoadFunction={(req) => this.loadDoctorData(req)}
								filterValue="personnel_expertise_contract_id"
								autoFocus={true}
								inputRenderer={(obj) => {
									return `${obj.full_name} - ${obj.provider_name} ${
										isSafe(obj.address) && isNotEmpty(obj.address.full_address) ? "/ " + obj.address.full_address : ""
									}`
								}}
								saveValue={(obj) => obj.personnel_expertise_contract_id}
								postAction={(obj) => {
									this.form.$("recipient_personnel_id").value = obj && obj.personnel_id ? obj.personnel_id : ""
									this.form.$("recipient_provider_id").value = obj && obj.provider_id ? obj.provider_id : ""
								}}
							/>
						</Grid>
						<Grid item container className="pt-2">
							<Grid item xs={6}>
								<XsInput field={this.form.$("subject")} />
							</Grid>
						</Grid>
						<Grid item container className="pt-2" spacing={8}>
							<Grid item xs={12}>
								<XsInput field={this.form.$("message")} multiple rows={3} />
							</Grid>
						</Grid>
					</Grid>
					<Grid container className="mt-4">
						<Grid item xs={12}>
							<XsButton className={"xs-primary"} text="Odoslať správu" onClick={(e) => this.form.onSubmit(e)} />
						</Grid>
					</Grid>
				</div>
				<div className="divider d-none d-md-block">
					<svg
						width="100%"
						height="96px"
						viewBox="0 0 100 100"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
					>
						<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
					</svg>
				</div>
			</section>
		)
	}
}
