"use strict"

import React from "react"
import {observer} from "mobx-react"

import RouterStore from "../../global/store/RouterStore"
import UIStore from "../stores/UIStore"

@observer
export default class FinancneZuctovanie extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<section className="bg-primary-3 has-divider text-light">
					<div className="container pt-1 pb-md-0">
						<div className="row my-3">
							<div className="col">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a className="pointer" onClick={() => RouterStore.push("./")}>
												am<b>bee</b>
											</a>
										</li>
										<li className="breadcrumb-item" aria-current="page">
											<a className="pointer" onClick={() => (UIStore.dokumentacia = "")}>
												Dokumentácia
											</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Finančné zúčtovanie
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h5 className="mb-4">Jednoducho dôležité.</h5>
								<h1 className="display-4 mb-4">Finančné zúčtovanie</h1>
								<p className="lead">
									Procesy a fukncie ambee pre prípravu a generovanie podkladov pre vyúčtovanie čínností a zaslanie
									podkladov pre zdravotné poisťovne.
								</p>
							</div>
						</div>
						<div className="row justify-content-center mb-lg-n7">
							<div className="col-xl-10 layer-1">
								<img src="public/images/ambee-calendar.png" alt="ambee Kalendáre a plánovanie" className="rounded" />
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
				</section>

				<section className="pt-4 pb-0">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-10">
								<article className="article article-portfolio">
									<p className="lead mb-3">
										Generovanie dávok pre finančné zúčtovanie so zdravotnými poisťovňami sa deje podľa dátových rozhraní
										publikovaných
										<a
											href="http://www.udzs-sk.sk"
											title="Otvoriť stránku Úradu pre dohľad nad zdravotnou starosltivosťou."
											target="_blank"
											rel="noopener noreferrer"
										>
											Úradom pre dohľad nad zdravotnou starostlivosťou
										</a>
										. Pri zmenách spôsob, obsah a formu aktualizujeme. Na to nemusíte myslieť.
									</p>
									<div className="h3 pt-3">Kapitačná dávka</div>

									<p>
										Bežne známa {"kapitačná dávka"} má svoj oficiálny kód aj názov:
										<b>748n</b>
										<i>Hlásenie o počte – prírastku – úbytku poistencov u lekára</i>.
									</p>
									<p>
										Dávku predkladajú poskytovatelia zdravotnej starostlivosti poskytujúci všeobecnú starostlivosť,
										poskytovatelia zdravotnej starostlivosti poskytujúci špecializovanú ambulantnú starostlivosť v
										odbore zubné lekárstvo a gynekológia a pôrodníctvo – zdravotnej poisťovni v prípade, ak je tento
										spôsob zmluvne dohodnutý so zdravotnou poisťovňou.
									</p>
									<p>
										Poskytovateľ ambulantnej zdravotnej starostlivosti vykazuje údaje o kapitovaných poistencoch
										elektronickou formou podľa dátového rozhrania. Ide o dávku 748n. Generovanie jej obsahu je závislé
										od toho, či a ako má poskytovateľ zaevidované dohodody o poskytovaní zdravotnej starostlivosti.
									</p>

									<section className="mt-3 pt-3 pb-0 mb-3">
										<div className="container">
											<div className="row justify-content-center">
												<div className="col">
													<div className="row">
														<div className="col-md-12 col-lg-12">
															<div className="card card-body bg-primary text-light hover-shadow-3d">
																<div className="d-flex justify-content-between mb-3">
																	<div className="text-small d-flex">
																		<div className="mr-2">
																			<a href="#">Náš tip</a>
																		</div>
																	</div>
																	<span className="badge bg-primary text-light">
																		<svg
																			className="icon icon-sm bg-white"
																			width="24"
																			height="24"
																			viewBox="0 0 24 24"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M16.5 5C14.8905 5 13.0082 6.82463 12 8C10.9918 6.82463 9.1095 5 7.5 5C4.651 5 3 7.22218 3 10.0504C3 13.1835 6 16.5 12 20C18 16.5 21 13.25 21 10.25C21 7.42177 19.349 5 16.5 5Z"
																				fill="#212529"
																			/>
																		</svg>
																		57
																	</span>
																</div>
																<div className="d-flex">
																	<a href="#" className="btn btn-lg btn-warning btn-round flex-shrink-0">
																		<svg
																			className="icon"
																			width="24px"
																			height="24px"
																			viewBox="0 0 24 24"
																			version="1.1"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<title>Chat</title>
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect opacity="0" x="0" y="0" width="24" height="24"></rect>
																				<polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5"></polygon>
																				<path
																					d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M8.5,13 C9.32842712,13 10,12.3284271 10,11.5 C10,10.6715729 9.32842712,10 8.5,10 C7.67157288,10 7,10.6715729 7,11.5 C7,12.3284271 7.67157288,13 8.5,13 Z M13.5,13 C14.3284271,13 15,12.3284271 15,11.5 C15,10.6715729 14.3284271,10 13.5,10 C12.6715729,10 12,10.6715729 12,11.5 C12,12.3284271 12.6715729,13 13.5,13 Z M18.5,13 C19.3284271,13 20,12.3284271 20,11.5 C20,10.6715729 19.3284271,10 18.5,10 C17.6715729,10 17,10.6715729 17,11.5 C17,12.3284271 17.6715729,13 18.5,13 Z"
																					fill="#000000"
																				></path>
																			</g>
																		</svg>
																	</a>
																	<div className="ml-3">
																		<a href="#">
																			<h4 className="mb-1">
																				Aktualizujte si platnosti dohôd o poskytovaní zdravotnej starostlivosti.
																			</h4>
																		</a>
																		<span className="text-small opacity-70">
																			Vyhnete sa chybným alebo odmietnutým záznamom od zdravotnej poisťovne.
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									{/* <!-- ambee TIP --> */}

									<div className="h3 pt-3">Ambulatná dávka</div>
									<p>
										Ambulatná dávka má tiež svoj oficiálny kód aj názov:
										<b>751a</b>
										<i>Dátové rozhranie - Vykazovanie výkonov v ambulantnej zdravotnej starostlivosti</i>. am<b>bee</b>{" "}
										podporuje generovanie aj týchto dávok.
									</p>
									<p>
										Dávku predkladajú poskytovatelia všeobecnej ambulantnej starostlivosti (VAS), gynekológovia, u
										ktorých je dohodnutá platba kapitáciou, zubní lekári, LSPP, záchranná ZS. Poskytovateľ ZS predkladá
										zdravotnej poisťovni pre každý kód PZS (ambulanciu) a kód lekára jednu dávku za príslušné zúčtovacie
										obdobie vo faktúre s poskytnutými zdravotnými výkonmi spoločne s pripočítateľnými položkami.
									</p>

									<section className="mt-3 pt-3 pb-0 mb-3">
										<div className="container">
											<div className="row justify-content-center">
												<div className="col">
													<div className="row">
														<div className="col-md-12 col-lg-12">
															<div className="card card-body bg-primary-2 text-light hover-shadow-3d">
																<div className="d-flex justify-content-between mb-3">
																	<div className="text-small d-flex">
																		<div className="mr-2">
																			<a href="#">Náš tip</a>
																		</div>
																	</div>
																	<span className="badge bg-primary text-light">
																		<svg
																			className="icon icon-sm bg-white"
																			width="24"
																			height="24"
																			viewBox="0 0 24 24"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M16.5 5C14.8905 5 13.0082 6.82463 12 8C10.9918 6.82463 9.1095 5 7.5 5C4.651 5 3 7.22218 3 10.0504C3 13.1835 6 16.5 12 20C18 16.5 21 13.25 21 10.25C21 7.42177 19.349 5 16.5 5Z"
																				fill="#212529"
																			/>
																		</svg>
																		93
																	</span>
																</div>
																<div className="d-flex">
																	<a href="#" className="btn btn-lg btn-primary btn-round flex-shrink-0">
																		<svg
																			className="icon"
																			width="24px"
																			height="24px"
																			viewBox="0 0 24 24"
																			version="1.1"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<title>Chat</title>
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect opacity="0" x="0" y="0" width="24" height="24"></rect>
																				<polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5"></polygon>
																				<path
																					d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M8.5,13 C9.32842712,13 10,12.3284271 10,11.5 C10,10.6715729 9.32842712,10 8.5,10 C7.67157288,10 7,10.6715729 7,11.5 C7,12.3284271 7.67157288,13 8.5,13 Z M13.5,13 C14.3284271,13 15,12.3284271 15,11.5 C15,10.6715729 14.3284271,10 13.5,10 C12.6715729,10 12,10.6715729 12,11.5 C12,12.3284271 12.6715729,13 13.5,13 Z M18.5,13 C19.3284271,13 20,12.3284271 20,11.5 C20,10.6715729 19.3284271,10 18.5,10 C17.6715729,10 17,10.6715729 17,11.5 C17,12.3284271 17.6715729,13 18.5,13 Z"
																					fill="#000000"
																				></path>
																			</g>
																		</svg>
																	</a>
																	<div className="ml-3">
																		<a href="#">
																			<h4 className="mb-1">Šetrite svoj čas. Bodovanie môžete ľahko automatizovať.</h4>
																		</a>
																		<span className="text-small opacity-70">
																			Používajte plánovanie a definície služieb. Niektoré veci za vás potom naboduje am
																			<b>bee</b>
																			sám.
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									{/* <!-- ambee TIP --> */}
								</article>
							</div>
						</div>
					</div>
				</section>

				<section className="has-divider pt-0">
					<div className="container pt-3">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<hr />
								<div className="d-flex align-items-center">
									<span className="text-small mr-1">Zdieľajte:</span>
									<div className="d-flex mx-2">
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Facebook</title>
												<path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>LinkedIn</title>
												<path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
											</svg>
										</a>
									</div>
								</div>
								<hr />
							</div>
						</div>
						<div className="row justify-content-center pt-3">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<div className="h4">
									O am<b>bee</b>
								</div>
								<p>
									am<b>bee</b> je moderný ambulantný systém pre všeobecných lekárov aj špecialistov. Vyvíjame ho spolu s
									lekármi a ich zdravotnými sestrami. Na ich názore záleží, am<b>bee</b> má pomáhať práve im asistovať
									pri spracovaní dennej agendy. Kedykoľvek a kdekoľvek.
								</p>
								<p>
									am<b>bee</b> prešiel v roku 2018 overením zhody v Národnom centre zdravotníckych informácií a
									testovali sme ho na komunikáciu so všetkými zdravotnými poisťovňami. Neustále ho rozvíjame a reagujeme
									na potreby lekárov či legislatívne zmeny.
								</p>
							</div>
						</div>
					</div>

					<div className="divider">
						<svg
							className="bg-primary-alt"
							width="100%"
							height="100%"
							version="1.1"
							viewbox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z" fill="#ffffff"></path>
						</svg>
					</div>
				</section>
				{/* <!-- share & About ambee -->                 */}
			</React.Fragment>
		)
	}
}
