"use strict"

// default imports for all farms
import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import fields from "./requestFormFields"
import bindings from "../../global/ui/globalUISchemeBindings"

import {DOCTOR} from "../../global/config/constants"
import {StorageBase} from "../../global/storage/storageEx"

import Grid from "@material-ui/core/Grid"

// import XsInput from "../../global/ui/xsInput/xsInput"
// import XsAutocompleteLocal from "../../global/ui/xsInput/xsAutocompleteLocal"
import XsChipForm from "../../global/ui/xsChipForm/xsChipForm"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import XsInput from "../../global/ui/xsInput/xsInput"
// import XsIconButton from "../../global/ui/xsButton/xsIconButton"
// import XsChip from "../../global/ui/xsChip/xsChip"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../global/ui/xsButton/xsButton"
import FirstLoginDialog from "../somPacient/firstLoginDialog"
import ChangePasswordDialog from "../somPacient/changePasswordDialog"

import api from "../actions/api"

import GlobalStore from "../../global/store/GlobalStore"
import PatientStore from "../stores/PatientStore"
import UIStore from "../stores/UIStore"

@injectIntl
@observer
export default class RequestForm extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["medicationSubTypes"])

		const hooks = {
			onSubmit(form) {
				const fromValues = form.values()
				const requests = JSON.parse(fromValues.requests)

				let emptyDrugs = false
				let invalidPackageFormat = false
				let invalidMorningFormat = false
				let invalidLunchFormat = false
				let invalidEveningFormat = false
				let invalidSleepFormat = false

				requests.forEach((req) => {
					if (isEmpty(req.drug)) {
						emptyDrugs = true
					}

					if (isNotEmpty(req.package) && (+req.package < 0 || +req.package > 9)) {
						invalidPackageFormat = true
					}

					if (isNotEmpty(req.morning) && (+req.morning < 0 || +req.morning > 9)) {
						invalidMorningFormat = true
					}

					if (isNotEmpty(req.lunch) && (+req.lunch < 0 || +req.lunch > 9)) {
						invalidLunchFormat = true
					}

					if (isNotEmpty(req.evening) && (+req.evening < 0 || +req.evening > 9)) {
						invalidEveningFormat = true
					}

					if (isNotEmpty(req.sleep) && (+req.sleep < 0 || +req.sleep > 9)) {
						invalidSleepFormat = true
					}
				})

				if (isEmpty(form.$("doctor").value) || emptyDrugs) {
					GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
					form.invalidate()
				} else if (invalidPackageFormat) {
					GlobalStore.setNotificationMessage("Môžete zadať maximálne 9 balení lieku")
					form.invalidate()
				} else if (invalidSleepFormat || invalidEveningFormat || invalidLunchFormat || invalidMorningFormat) {
					GlobalStore.setNotificationMessage("Dávkovanie môžete zadať v počte max. 9")
					form.invalidate()
				}
			},
			onSuccess(form) {
				PatientStore.prescDraft(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	handleTypeChange = (form) => {
		let tmpData = form.get("value")
		tmpData.drug = ""
		tmpData.package = ""
		tmpData.morning = ""
		tmpData.lunch = ""
		tmpData.evening = ""
		tmpData.sleep = ""
		tmpData.medEquip = ""

		form.set(tmpData)
	}

	handleChipformChange = (form, doseUnit) => {
		let values = form.get("value")
		if (isNotEmpty(doseUnit)) {
			values.medEquip = doseUnit
		}
		form.set(values)
	}

	getDoseUnit = (form) => {
		let values = form.get("value")
		if (isNotEmpty(values.medEquip)) {
			return values.medEquip
		} else {
			return ""
		}
	}

	renderDrugCol = (field, index, fieldKey, handleChange, returnFormData) => {
		if (returnFormData(fieldKey).get("value").type == "RBULK") {
			return (
				<XsAutocomplete
					field={field}
					api={api.loadDrugs}
					minLengthForSearch="3"
					clearText
					autoFocus={true}
					inputRenderer={(obj) =>
						obj.name_ext +
						" | " +
						obj.supplement +
						" | " +
						(obj.patient_payment ? `doplatok: ${obj.patient_payment} EUR` : "")
					}
					saveValue={(obj) => obj.code}
				/>
			)
		} else if (returnFormData(fieldKey).get("value").type == "MEDEQUIP") {
			return (
				<XsAutocomplete
					field={field}
					api={api.loadDevices}
					minLengthForSearch="3"
					clearText
					autoFocus={true}
					inputRenderer={(obj) =>
						obj.name_ext +
						" | " +
						obj.supplement +
						" | " +
						(obj.patient_payment ? `doplatok: ${obj.patient_payment} EUR` : "")
					}
					postAction={(value) => {
						this.handleChipformChange(handleChange(fieldKey), value.dose_amount_unit_code_ext)
					}}
					saveValue={(obj) => obj.code}
				/>
			)
		} else {
			return (
				<XsAutocomplete
					field={field}
					api={api.loadDietetic}
					minLengthForSearch="3"
					clearText
					autoFocus={true}
					inputRenderer={(obj) =>
						obj.name_ext +
						" | " +
						obj.supplement +
						" | " +
						(obj.patient_payment ? `doplatok: ${obj.patient_payment} EUR` : "")
					}
					saveValue={(obj) => obj.code}
				/>
			)
		}
	}

	render() {
		return (
			<section className="bg-primary-3 has-divider text-light" style={{paddingTop: "6rem"}}>
				<div className="container pt-1 pb-md-0">
					<div className="row justify-content-center text-center mb-6">
						<div className="col-xl-8 col-lg-9 col-md-10">
							<h1 className="display-4 mb-4">Požiadavka na predpis</h1>
							<p className="lead pb-4">
								Prostredníctvom tohto formulára môžete požiadať lekára o predpisanie lieku, pomôcky alebo dietetickej
								potraviny.
							</p>
							{!UIStore.isLoading && (
								<Grid container direction="column">
									<Grid item className="lead text-left pb-2">
										Krok 1:
									</Grid>

									<Grid item container alignItems="baseline">
										<Grid item xs={9}>
											<XsAutocomplete
												field={this.form.$("doctor")}
												minLengthForSearch="3"
												clearText
												api={api.loadDoctor}
												filterValue="personnel_expertise_contract_id"
												// data={this.captcha()}
												autoFocus={true}
												// ref={(input) => (GlobalStore.defaultFocusFields["patientMedicationForm"] = input)}
												inputRenderer={(obj) => {
													return `${obj.full_name} - ${obj.provider_name} ${
														isSafe(obj.address) && isNotEmpty(obj.address.full_address)
															? "/ " + obj.address.full_address
															: ""
													}`
												}}
												saveValue={(obj) => obj.personnel_expertise_contract_id}
												onChange={(obj) => {
													if (this.form.$("rememberDoctor").value) {
														StorageBase.updateByKey(DOCTOR, obj)
													}
												}}
												onClear={() => {
													if (this.form.$("rememberDoctor").value) {
														StorageBase.deleteByKey(DOCTOR)
													}
												}}
											/>
										</Grid>
										<Grid item xs={3} className="pl-3">
											<XsCheckbox
												field={this.form.$("rememberDoctor")}
												onChange={(field) => {
													if (field.value && isNotEmpty(this.form.$("doctor").value)) {
														StorageBase.updateByKey(DOCTOR, this.form.$("doctor").value)
													} else {
														StorageBase.deleteByKey(DOCTOR)
													}
												}}
											/>
										</Grid>
									</Grid>
									<Grid item className="lead text-left pt-4">
										Krok 2:
									</Grid>
									<XsChipForm
										ref={(chipForm) => (PatientStore.requestChipForm = chipForm)}
										field={this.form.$("requests")}
										configuration={{
											columns: [
												{
													name: "type",
													label: "Typ preskripcie",
													width: 4,
													validationRule: "required|string",
													renderer: (field, index, fieldKey, handleChange) => (
														<XsSearchSelect
															field={field}
															items={
																isSafe(GlobalStore.CL["medicationSubTypes"])
																	? GlobalStore.CL["medicationSubTypes"].filter(
																			(data) =>
																				data.code != "ADMDRUG" &&
																				data.code != "ADMREACTION" &&
																				data.code != "ADMVACC" &&
																				data.code != "RINDIVID"
																	  )
																	: []
															}
															onChange={() => {
																this.handleTypeChange(handleChange(fieldKey))
															}}
															required
														/>
													)
												},
												{
													name: "drug",
													label: <FormattedMessage id="Common.label.selectDrug" />,
													width: 6,
													validationRule: "required|string",
													renderer: (field, index, fieldKey, handleChange, returnFormData) =>
														this.renderDrugCol(field, index, fieldKey, handleChange, returnFormData)
												},
												{
													name: "package",
													label: <FormattedMessage id="Common.label.amount" />,
													width: 2,
													validationRule: "string",
													renderer: (field, index, fieldKey, handleChange) => (
														<XsInput
															submitAction={(e) => this.form.onSubmit(e)}
															endText={this.getDoseUnit(handleChange(fieldKey))}
															field={field}
															type="number"
															min="0"
															step="1"
														/>
													)
												},
												{
													name: "morning",
													label: <FormattedMessage id="Common.label.morning" />,
													width: 3,
													validationRule: "string",
													renderer: (field, index, fieldKey, handleChange, returnFormData) => {
														if (returnFormData(fieldKey).get("value").type === "RBULK")
															return (
																<XsInput
																	submitAction={(e) => this.form.onSubmit(e)}
																	field={field}
																	type="number"
																	min="0"
																	max="9"
																	step="1"
																/>
															)
													}
												},
												{
													name: "lunch",
													label: <FormattedMessage id="Common.label.lunch" />,
													width: 3,
													validationRule: "string",
													renderer: (field, index, fieldKey, handleChange, returnFormData) => {
														if (returnFormData(fieldKey).get("value").type === "RBULK")
															return (
																<XsInput
																	submitAction={(e) => this.form.onSubmit(e)}
																	field={field}
																	type="number"
																	min="0"
																	max="9"
																	step="1"
																/>
															)
													}
												},
												{
													name: "evening",
													label: <FormattedMessage id="Common.label.evening" />,
													width: 3,
													validationRule: "string",
													renderer: (field, index, fieldKey, handleChange, returnFormData) => {
														if (returnFormData(fieldKey).get("value").type === "RBULK")
															return (
																<XsInput
																	submitAction={(e) => this.form.onSubmit(e)}
																	field={field}
																	type="number"
																	min="0"
																	max="9"
																	step="1"
																/>
															)
													}
												},
												{
													name: "sleep",
													label: <FormattedMessage id="Common.label.sleep" />,
													width: 3,
													validationRule: "string",
													renderer: (field, index, fieldKey, handleChange, returnFormData) => {
														if (returnFormData(fieldKey).get("value").type === "RBULK")
															return (
																<XsInput
																	submitAction={(e) => this.form.onSubmit(e)}
																	field={field}
																	type="number"
																	min="0"
																	max="9"
																	step="1"
																/>
															)
													}
												},
												{
													name: "label",
													label: "aaa",
													width: 12,
													renderer: (field, index, fieldKey, handleChange, returnFormData) => {
														if (returnFormData(fieldKey).get("value").type === "RBULK")
															return (
																<div
																	style={{
																		textAlign: "left",
																		color: "#F0F3F8",
																		fontWeight: 400,
																		fontSize: "0.8rem",
																		fontFamily: "Roboto"
																	}}
																>
																	Ak poznáte dávkovanie vášho lieku, môžete ho vyplniť.
																</div>
															)
													}
												}
											],
											options: {
												hideHeader: true,
												disableFirstRowDelete: true,
												isFormMode: true,
												isModalMode: false,
												hideButtons: true,
												startOpen: true,
												onFormOpen: () => {},
												onFormClose: () => {},
												onFormSave: (values) => {
													PatientStore.requestChipFormData = values
												}
											},
											data: {}
										}}
									/>
									<Grid item className="pt-4">
										<XsButton
											className="xs-primary"
											text="Požiadať o elektronický recept"
											onClick={this.form.onSubmit}
										/>
									</Grid>
								</Grid>
							)}
							<p className="text-muted mt-5">Pripravujeme pre Vás ďalšie funkcionality pacientského portálu.</p>
						</div>
					</div>
				</div>

				<div className="divider d-none d-md-block">
					<svg
						width="100%"
						height="96px"
						viewBox="0 0 100 100"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
					>
						<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
					</svg>
				</div>
				<FirstLoginDialog />
				<ChangePasswordDialog />
			</section>
		)
	}
}
