"use strict"

import {observable, action} from "mobx"

class UIStore {
	@observable faqData = []

	@action getFaqData() {
		let formBody = []

		let details = {
			nUser_id: 0,
			vColumns: "a.title,a.perex,a.content,a.state,tlg.name,tlg.code",
			vTableName:
				"articles a join object_tags ot on ot.object_id=a.id and ot.is_deleted=0 join tags t on t.id=ot.tag_id and t.is_deleted=0 and t.category='articles' and t.object_name = 'articles' and t.code='AMBEEWEBFAQ' join object_tags otlg on otlg.object_id=a.id and otlg.is_deleted=0 join tags tlg on tlg.id=otlg.tag_id and tlg.is_deleted=0 and tlg.category='articles' and tlg.object_name = 'articles'",
			vWhereClause: "a.is_deleted=0 and a.state='P' order by tlg.code"
		}

		for (let property in details) {
			let encodedKey = encodeURIComponent(property)
			let encodedValue = encodeURIComponent(details[property])
			formBody.push(encodedKey + "=" + encodedValue)
		}
		formBody = formBody.join("&")

		fetch("https://ema.ambee.sk/api/apisp.php/sp_universal_query", {
			method: "POST",
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: formBody
		})
			.then((res) => (isSafe(res) ? res.json() : null))
			.then((data) => {
				this.faqData = data[0]
			})
	}
}

var singleton = new UIStore()
export default singleton
