"use strict"

import React from "react"
import {observer} from "mobx-react"

import RouterStore from "../../global/store/RouterStore"
import UIStore from "../stores/UIStore"

@observer
export default class ZacinameAmbee extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<section className="bg-primary-3 has-divider text-light">
					<div className="container pt-1 pb-md-0">
						<div className="row my-3">
							<div className="col">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a className="pointer" onClick={() => RouterStore.push("./")}>
												am<b>bee</b>
											</a>
										</li>
										<li className="breadcrumb-item" aria-current="page">
											<a className="pointer" onClick={() => (UIStore.dokumentacia = "")}>
												Dokumentácia
											</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Začíname s am<b>bee</b>
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h5 className="mb-4">Stačí vám zopár minút</h5>
								<h1 className="display-4 mb-4">Začíname s ambee</h1>
								<p className="lead">
									Pripomeňte si alebo sa naučte, ako ľahko môžete začať pracovať s vaším novým moderným ambulantným
									softvérom.
								</p>
							</div>
						</div>
						<div className="row justify-content-center mb-lg-n7">
							<div className="col-xl-10 layer-1">
								<img src="public/images/ambee-calendar.png" alt="ambee Kalendáre a plánovanie" className="rounded" />
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
				</section>

				<section className="pt-4 pb-0">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-10">
								<article className="article article-portfolio">
									<p className="lead mb-3">
										S vaším ambulatným systémom môžete pracovať cez internetový prehliadač kedykoľvek a kdekoľvek.
										Samozrejme, ak je nainštalovaný tak, aby ste k nemu vedeli pristúpiť napríklad aj z domu.
									</p>
									<p className="lead mb-3">
										Bezpečnosť je veľmi dôležitá. Naučíme sa, ako sa do systému prihlásite a ako pridáte alebo zmeníte
										údaje niektorého z vašich pacientov. Zároveň si vyskúšame, ako fungujú formuláre v am<b>bee</b>.
									</p>
									<div className="h3 pt-3">Prihlásenie</div>
									<p>
										Predpokladáme, že už máte licenciu am<b>bee</b> alebo si ho bezplatne skúšate po dobu 30 dní. Pred
										prvým prihlásením musíte poznať minimálne tieto 3 údaje:
									</p>
									<ul>
										<li>
											URL adresu am<b>bee</b>
											<span className="text-muted">(napr. moj.ambee.sk)</span>
										</li>
										<li>
											Prihlasovacie meno
											<span className="text-muted">(napr. milan.moderny@ambee.sk)</span>
										</li>
										<li>
											Heslo <span className="text-muted">(napr. 48$a-FaX8-4851)</span>
										</li>
									</ul>
									<p>
										Údaje vám pridelí administrátor systému. Ich vytvorenie je súčasťou procesu konfigurácie vašej
										verzie am<b>bee</b>. Nikdy ich nepošleme v jednom maili alebo SMS. Prihlasovacie meno a heslo budú
										vždy oddelené. Bezpečne si ich uložte.
									</p>
									<p>
										Do adresy vo vašom obľúbenom internetovom prehliadači napíšte adresu ambee. Objaví sa prvá obrazovka
										s výzvou na zadanie
										<b>Prihlasovacieho mena</b> a <b>Hesla</b>. Zadajte vaše údaje a kliknite na tlačidlo{" "}
										<b>Prihlásiť sa</b>. Ak sú údaje zadané správne, zobrazí sa domovská obrazovka vášho ambulatného
										systému. Vitajte :)!
									</p>
									<p>
										Ak ste sa pomýlili, budete musieť vaše prihlasovacie údaje zadať znova. Ak ste ich zabudli, nič to.
										Kliknite na
										<b>Zabudli ste heslo?</b> Vygenerujeme a doručíme vám nové.
									</p>
									<figure>
										<img
											src="public/images/ambee-login.png"
											alt="ambee | Prihlásenie do aplikácie"
											className="rounded"
										/>
										<figcaption>
											Prihlásenie do aplikácie am<b>bee</b>
										</figcaption>
									</figure>

									<div className="h3 pt-3">Kartotéka pacientov</div>
									<p>
										am<b>bee</b> je váš asistent. Pacientsko-centrický. Kartotéka pacientov obsahuje všetky
										identifikačné, adresné a kontaktné údaje vašich pacientov spolu s údajmi o ich poistení a Dohodách o
										poskytovaní zdravotnej starostlivosti. Tieto údaje môžete pridávať aj meniť. Tak, ako to bude život
										v ambulancii vyžadovať.
									</p>
									<p>
										Pri pri konfigurácii am<b>bee</b> je možné identifikačné, adresné a kontaktné údaje preniesť zo
										starého do vášho nového moderného informačného systému. Či sa pre migáciu dát pacientov rozhodnete
										alebo nie, z času na čas budete potrebovať pacienta pridať alebo upraviť niektoré jeho údaje.
									</p>
									<blockquote className="bg-primary-alt">
										<div className="h3 mb-2">
											am<b>bee</b> mi veľmi uľahčuje administratívu, preto sa môžem naplno venovať liečbe svojich
											pacientov.
										</div>
										<span className="text-small text-muted">– MUDr. Pavel Staško, PhD., MHA, cievny chirurg</span>
										<a href="#" className="btn btn-primary btn-sm">
											<svg className="icon" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
											<span>Tweet</span>
										</a>
									</blockquote>

									<div className="h4">Ako môžem pridať pacienta?</div>
									<p>
										Na domovskej stránke am<b>bee</b> sa nachádza tlačidlo
										<b>Pridať pacienta</b>. Ak chcete pridať nového pacienta, kliknite na toto tlačidlo. am<b>bee</b>{" "}
										otvorí formulár pre pridanie údajov nového pacienta.
									</p>
									<p>
										Formulár pre zadanie identifikačných a adresných údajov obsahuje povinné aj nepovinné polia. Tie
										povinné sú orámované
										<span className="badge badge-danger">červenou</span> farbou. Ak ich nevyplníte, údaje nebude možné
										uložiť. Systém vás upozorní.
									</p>
									<p>
										Po zadaní a kontrole údajov kliknite na tlačidlo <b>Uložiť</b>. Ak údaje ukladať nechcete a teda
										nechcete, aby am<b>bee</b>
										vytvoril nového pacienta vo vašej kartotéke, kliknite na tlačidlo <b>Neukladať</b>.
									</p>
									<figure>
										<div className="popover-image">
											<div
												className="popover-hotspot bg-primary-2"
												style={{top: "42%", left: "17%"}}
												data-toggle="popover"
												title="Povinné pole"
												data-content="Takto označené políčko musíte vyplniť. Pred uložením nemôže byť prázdne. "
											></div>
											<div
												className="popover-hotspot bg-primary-2"
												style={{top: "33%", left: "88%"}}
												data-toggle="popover"
												title="Uložiť pacienta"
												data-content="Ak chcete úložiť údaje a vytvoriť tak vo vašej elektronickej kartotéke nového pacienta, kliknite na toto tlačidlo. Ak nechcete nič uložiť, klinite na tlačidlo Neukldať. "
											></div>
											<div
												className="popover-hotspot bg-primary-2"
												style={{top: "69%", left: "17%"}}
												data-toggle="popover"
												title="Nepovinné pole"
												data-content="Takto zobrazené políčko môžete nechať prázdnu. Odporúčame vám zadávať aj nepovinné údaje. Nikde neviete, kedy sa vám zídu. Získať ich nie je vždy jednoduché. "
											></div>
											<img
												src="public/images/ambee-patient-new.png"
												alt="ambee | Pridať nového pacienta "
												className="rounded"
											/>
											<figcaption>Formulár pre pridanie nového pacienta</figcaption>
										</div>
									</figure>

									<div className="h4">Ako zmením údaje existujúceho pacienta?</div>
									<p>
										Veľmi jednoducho. Na domovskej stránke si vyhľadáte pacienta, kliknte na riadok, kde sú zobrazené
										údaje a am<b>bee</b> vám zobrazí podobný formulár pre pridanie nového pacienta. Rozdiel je iba v
										tom, že polia formulára budú naplnené dátami pacienta. Formulár je zobrazený iba na čítanie.
									</p>
									<p>
										Ak chcete údaje zmeniť, kliknite na tlačidlo <b>Upraviť</b>. Upravte, doplňte alebo zmeňte údaje v
										políčkach a kliknite na tlačidlo <b>Uložiť</b>. Ak zmeny nechcete ukladať, kliknite na tlačidlo{" "}
										<b>Neukladať</b>.
									</p>

									<section>
										<div className="container">
											<div className="row justify-content-center">
												<div className="col">
													<div className="row">
														<div className="col-md-12 col-lg-12">
															<div className="card card-body bg-primary-2 text-light hover-shadow-3d">
																<div className="d-flex justify-content-between mb-3">
																	<div className="text-small d-flex">
																		<div className="mr-2">
																			<a href="#">Náš tip</a>
																		</div>
																	</div>
																	<span className="badge bg-primary text-light">
																		<svg
																			className="icon icon-sm bg-white"
																			width="24"
																			height="24"
																			viewBox="0 0 24 24"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M16.5 5C14.8905 5 13.0082 6.82463 12 8C10.9918 6.82463 9.1095 5 7.5 5C4.651 5 3 7.22218 3 10.0504C3 13.1835 6 16.5 12 20C18 16.5 21 13.25 21 10.25C21 7.42177 19.349 5 16.5 5Z"
																				fill="#212529"
																			/>
																		</svg>
																		114
																	</span>
																</div>
																<div className="d-flex">
																	<a href="#" className="btn btn-lg btn-primary btn-round flex-shrink-0">
																		<svg
																			className="icon"
																			width="24px"
																			height="24px"
																			viewBox="0 0 24 24"
																			version="1.1"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<title>TIP</title>
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<path
																					d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
																					fill="#000000"
																				></path>
																				<rect
																					fill="#000000"
																					opacity="0.3"
																					x="10"
																					y="16"
																					width="4"
																					height="4"
																					rx="2"
																				></rect>
																			</g>
																		</svg>
																	</a>
																	<div className="ml-3">
																		<a href="#">
																			<h4 className="mb-1">
																				Kontaktné údaje sú dôležité. Evidujte ich vždy, keď máte príležitosť.
																			</h4>
																		</a>
																		<span className="text-small opacity-70">
																			Najlepšie urobíte, ak si ich lekár alebo sestra overí, kým je pacient v
																			ambulancii.
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									{/* <!-- ambee TIP --> */}
								</article>
							</div>
						</div>
					</div>
				</section>

				<section className="has-divider pt-0">
					<div className="container pt-3">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<hr />
								<div className="d-flex align-items-center">
									<span className="text-small mr-1">Zdieľajte:</span>
									<div className="d-flex mx-2">
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Facebook</title>
												<path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>LinkedIn</title>
												<path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
											</svg>
										</a>
									</div>
								</div>
								<hr />
							</div>
						</div>
						<div className="row justify-content-center pt-3">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<div className="h4">
									O am<b>bee</b>
								</div>
								<p>
									am<b>bee</b> je moderný ambulantný systém pre všeobecných lekárov aj špecialistov. Vyvíjame ho spolu s
									lekármi a ich zdravotnými sestrami. Na ich názore záleží, am<b>bee</b> má pomáhať práve im asistovať
									pri spracovaní dennej agendy. Kedykoľvek a kdekoľvek.
								</p>
								<p>
									am<b>bee</b> prešiel v roku 2018 overením zhody v Národnom centre zdravotníckych informácií a
									testovali sme ho na komunikáciu so všetkými zdravotnými poisťovňami. Neustále ho rozvíjame a reagujeme
									na potreby lekárov či legislatívne zmeny.
								</p>
							</div>
						</div>
					</div>

					<div className="divider">
						<svg
							className="bg-primary-alt"
							width="100%"
							height="100%"
							version="1.1"
							viewBox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z" fill="#ffffff"></path>
						</svg>
					</div>
				</section>
				{/* <!-- share & About ambee --> */}
			</React.Fragment>
		)
	}
}
