"use strict"

import {injectIntl} from "react-intl"
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../global/ui/globalUISchemeBindings"
import classnames from "classnames"
import {load} from "recaptcha-v3"
import {isEmailValid} from "../../global/helpers/functions"

import {getDoctor} from "../../global/helpers/actions"
import {Grid} from "@material-ui/core"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../global/ui/xsInput/xsInput"

import PatientStore from "../stores/PatientStore"
import GlobalStore from "../../global/store/GlobalStore"
import setting from "../../global/config/settings"
import api from "../actions/api"

@injectIntl
@observer
export default class SendMessageToDoctorWithoutLogin extends React.Component {
	constructor(props) {
		super(props)

		window.scrollTo(0, 0)

		const hooks = {
			onSubmit(form) {
				if (!isEmailValid(form.$("email").value)) {
					GlobalStore.setNotificationMessage("Zadajte validní email!")
					form.$("email").invalidate()
				}
			},
			onSuccess(form) {
				PatientStore.sendMessageToDoctorUnauth(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					personnel_expertise_contract_id: {
						label: "Napíšte jeho meno, priezvisko, prípadne adresu ambulancie",
						rules: "required",
						value: getDoctor()
					},
					recipient_personnel_id: {
						rules: "required"
					},
					recipient_provider_id: {},
					sender_patient_email: {
						label: "Zadajte Vašu emailovú adresu",
						rules: "required"
					},
					subject: {
						label: "Predmet správy",
						rules: "required"
					},
					message: {
						label: "Text správy",
						rules: "required"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		PatientStore.orderFormRef = this.form
	}

	componentDidMount() {
		let scripts = [
			"../public/scripts/jquery.min.js",
			"../public/scripts/popper.min.js",
			"../public/scripts/bootstrap.js",
			"../public/scripts/moment.min.js",
			"../public/scripts/moment-business.js",
			"../public/scripts/aos.js",
			"../public/scripts/clipboard.js",
			"../public/scripts/jquery.fancybox.min.js",
			"../public/scripts/flickity.pkgd.min.js",
			"../public/scripts/ion.rangeSlider.min.js",
			"../public/scripts/isotope.pkgd.min.js",

			"../public/scripts/jarallax.min.js",
			"../public/scripts/jarallax-video.min.js",
			"../public/scripts/jarallax-element.min.js",
			"../public/scripts/jquery.countdown.min.js",
			"../public/scripts/plyr.polyfilled.min.js",
			"../public/scripts/prism.js",
			"../public/scripts/scrollMonitor.js",
			"../public/scripts/smooth-scroll.polyfills.js",
			"../public/scripts/twitterFetcher_min.js",
			"../public/scripts/typed.min.js",
			"../public/scripts/theme.js",
			"../public/scripts/controller.js",

			"../public/scripts/sweetalert.min.js",
			"../public/scripts/maine.js"
		]

		for (let i = 0; i < scripts.length; i++) {
			const script = document.createElement("script")
			script.src = scripts[i]
			script.async = false
			// script.onload = () => document.querySelector("body").classList.add("loaded")

			document.body.appendChild(script)
		}
		document.querySelector("body").classList.add("loaded")

		//Personnnel Expertise Contract ID in query parameter
		const searchParams = new URLSearchParams(window.location.search)
		const paramValue = searchParams.get("pecId")

		if (paramValue) {
			this.loadDoctorDataWithRecaptcha({
				filters: [
					{associated_column: "personnel_expertise_contract_id", predicate: "=", values: [{id_value: paramValue}]}
				],
				row_count_full: 1
			}).then((rows) => {
				if (rows && rows.length) {
					const obj = rows.find((row) => row.personnel_expertise_contract_id === paramValue)
					if (obj) {
						this.form.$("personnel_expertise_contract_id").value = obj.personnel_expertise_contract_id
						this.postActionDoctorSearch(obj)
					}
				}
			})
		}
	}

	componentWillUnmount() {
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; i++) {
			scripts[i].parentNode.removeChild(scripts[i])
		}
	}

	loadDoctorDataWithRecaptcha = (request) => {
		return new Promise(function(resolve) {
			load(setting.SITE_KEY).then((recaptcha) => {
				recaptcha.execute("patient_search_personnel").then((recaptchaToken) => {
					api
						.loadDoctorUnauth(request, recaptchaToken)
						.call()
						.then((res) => resolve(res.rows))
				})
			})
		})
	}

	postActionDoctorSearch = (obj) => {
		this.form.$("recipient_personnel_id").value = obj && obj.personnel_id ? obj.personnel_id : ""
		this.form.$("recipient_provider_id").value = obj && obj.provider_id ? obj.provider_id : ""
	}

	render() {
		return (
			<section className={classnames("bg-primary-3 has-divider text-light")} id="sendMessageToDoctor">
				<div className="container pb-md-0">
					<Grid container>
						<Grid item xs={12}>
							Vyplnením položiek formulára môžete odoslať lekárovi správu alebo otázku. Lekár Vám na ňu bude odpovedať
							správou, ktorá bude doručená do Vašej emailovej schránky, kde následne môžete v prípade potreby pokračovať
							v mailovej komunikácii s lekárom.
						</Grid>
						<Grid item xs={6} className="pt-5 pr-1">
							<XsAutocomplete
								field={this.form.$("personnel_expertise_contract_id")}
								minLengthForSearch="3"
								clearText
								specialLoadFunction={(req) => this.loadDoctorDataWithRecaptcha(req)}
								filterValue="personnel_expertise_contract_id"
								autoFocus={true}
								inputRenderer={(obj) => {
									return `${obj.full_name} - ${obj.provider_name} ${
										isSafe(obj.address) && isNotEmpty(obj.address.full_address) ? "/ " + obj.address.full_address : ""
									}`
								}}
								saveValue={(obj) => obj.personnel_expertise_contract_id}
								postAction={(obj) => this.postActionDoctorSearch(obj)}
							/>
						</Grid>
						<Grid item container xs={12} className="pt-2">
							<Grid item xs={6} className="pr-1">
								<XsInput field={this.form.$("sender_patient_email")} />
							</Grid>
							<Grid item xs={6} className="pl-1">
								<XsInput field={this.form.$("subject")} />
							</Grid>
						</Grid>
						<Grid item container className="pt-2" spacing={8}>
							<Grid item xs={12}>
								<XsInput field={this.form.$("message")} multiple rows={3} />
							</Grid>
						</Grid>
					</Grid>
					<Grid container className="mt-4">
						<Grid item xs={12}>
							<XsButton className={"xs-primary"} text="Odoslať správu" onClick={(e) => this.form.onSubmit(e)} />
						</Grid>
					</Grid>
				</div>
				<div className="divider d-none d-md-block">
					<svg
						width="100%"
						height="96px"
						viewBox="0 0 100 100"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
					>
						<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
					</svg>
				</div>
			</section>
		)
	}
}

