import {FormattedMessage} from "react-intl"
import React from "react"

import {getDoctor, getClientID} from "../../global/helpers/actions"

export default {
	load() {
		return {
			fields: {
				doctor: {
					label: <FormattedMessage id="Common.label.selectDoctor" />,
					value: isNotEmpty(getDoctor()) ? getDoctor() : "",
					rules: "required"
				},
				// doctorId: {},
				rememberDoctor: {
					label: "Zapamätať",
					value: isNotEmpty(getDoctor()) ? true : false,
					type: "checkbox"
				},
				person: {
					label: "Pre koho",
					value: getClientID(),
					rules: "required"
				},
				requests: {
					value: JSON.stringify([
						{
							type: "RBULK",
							drug: "",
							medEquip: "",
							package: "",
							label: "",
							morning: "",
							lunch: "",
							evening: "",
							sleep: ""
						}
					])
				}
			}
		}
	}
}
