"use strict"

import {FormattedMessage} from "react-intl"
import React from "react"
import {observer} from "mobx-react"

import XsLoading from "../../global/ui/xsLoading/xsLoading"
import XsButton from "../../global/ui/xsButton/xsButton"
import {StorageBase} from "../../global/storage/storageEx"
import * as types from "../../global/config/constants"

import PatientStore from "../stores/PatientStore"
import RouterStore from "../../global/store/RouterStore"
import UIStore from "../stores/UIStore"

@observer
export default class LoginPacient extends React.Component {
	constructor(props) {
		super(props)
	}

	componentWillUnmount() {
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; i++) {
			scripts[i].parentNode.removeChild(scripts[i])
		}
	}

	render() {
		return (
			<React.Fragment>
				{UIStore.isLoading && <XsLoading overlay={true} />}
				<section className="bg-primary-3 has-divider text-light" style={{paddingTop: "6rem"}}>
					<div className="container pt-1 pb-md-0">
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h1 className="display-4 mb-4">Vitajte v sekcii určenej pre pacientov</h1>
								<p className="lead">
									Pomocou kontaktného formulára môžete požiadať lekára o vystavenie receptu na liek, pomôcku alebo
									dietetickú potravinu. V sekcii objednanie sa u lekára môžete objednať na konkrétny druh vyšetrenia.
									Dátum a čas objednania závisí od zverejnených ordinačných hodín daného lekára.
								</p>
								<XsButton
									className="xs-primary mt-5"
									text={<FormattedMessage id="Common.label.login" />}
									onClick={() => (UIStore.status = "login")}
								/>
								<XsButton
									className="xs-primary mt-5"
									text="Objednať sa bez prihlásenia"
									onClick={() => {
										StorageBase.deleteByKeyQuery(
											(x) =>
												!(
													isSafe(x.type) ||
													x == types.USER_SETTINGS ||
													x == types.LANGUAGE ||
													x == types.DZPDATE ||
													x == types.DOCTOR
												)
										)
										PatientStore.emptySlots = []
										RouterStore.push("./order")
									}}
								/>
								<XsButton
									className="xs-primary mt-3"
									text="Poslať správu lekárovi"
									onClick={() => {
										RouterStore.push("./sendMessage")
									}}
								/>
								<p className="text-muted mt-5">
									Ak ešte nemáte prihlasovacie údaje, kontaktujte Vášho ošetrujúceho lekára, ktorý Vám na Vašu emailovú
									adresu zašle pozývací email, ktorým sa budete vediet do portálu prihlásiť.
								</p>
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
				</section>
			</React.Fragment>
		)
	}
}
