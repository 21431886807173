import React from "react"
import {observer} from "mobx-react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import "./xsInput.less"
import {getFieldIdentifier} from "../../helpers/actions"

@observer
export default class XsInput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isValid: true
		}

		this._isMounted = false
	}

	componentDidMount() {
		this._isMounted = true
		if (isSafe(this.props.field) && this.props.disabled !== true) {
			this.handleValidation(this.props.field)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	inputRef = undefined

	handleValidation = (field) => {
		field.validate().then(({isValid}) => {
			if (this.props.type == "number" && isNotEmpty(field.value) && +this.props.min > +field.value) {
				isValid = false
			}
			if (this.props.type == "number" && isNotEmpty(field.value) && +this.props.max < +field.value) {
				isValid = false
			}
			if (this.state.isValid !== isValid) {
				if (this._isMounted) {
					this.setState({
						isValid: isValid
					})
				}
			}
		})
	}

	handleOnChange = (val, field, onChange) => {
		//EMA-9078 Ak sa nastavi inputu step tak sa neda zadat hodnota s vacsim poctom cisel za desatinou ciarkov ako ma step
		if (isNotEmpty(this.props.step) && this.props.step != "any") {
			let stepDecimalLength =
				isSafe(this.props.step.toString().split(".")) && isSafe(this.props.step.toString().split(".")[1])
					? this.props.step.toString().split(".")[1].length
					: 0
			let decimals = val.split(".")[1]
			if (isSafe(decimals) && decimals.length > stepDecimalLength) {
				let tmpNumber = +val
				val = tmpNumber.toFixed(stepDecimalLength).toString()
			}
		}
		field.set(val)
		field.sync(field.get("value"))
		if (isSafe(onChange) && typeof onChange === "function") {
			onChange(field.get("value"))
		}
		this.handleValidation(field)
	}

	focus = () => {
		this.inputRef.focus()
	}

	render() {
		const {
			field,
			labelStyle,
			white,
			type = "text",
			placeholder,
			validatingText,
			hideLabel,
			submitAction,
			multiple = false,
			shrink = true,
			rows,
			autoFocus = false,
			clearText,
			disabled,
			onChange,
			min,
			max,
			step,
			onBlur,
			maxLength
		} = this.props

		const bindedField = field.bind()

		const labelJSX =
			isSafe(field.get("rules")) && field.get("rules").includes("required") ? (
				<span className="xs-input-label xs-required">{bindedField.label} *</span>
			) : (
				<span className="xs-optional">{bindedField.label}</span>
			)

		// if (autoFocus) {
		//   field.focus()
		// }
		let inputClassName = ""

		if (!this.state.isValid) {
			inputClassName = "xs-input-error"
		}

		if (white) {
			inputClassName += " xs-white"
		}

		inputClassName = getFieldIdentifier(field, inputClassName)

		return (
			<TextField
				InputLabelProps={{
					style: labelStyle,
					shrink: shrink
				}}
				autoComplete="off"
				InputProps={{
					classes: {
						root: inputClassName //this.state.isValid ? "" : "xs-input-error"
					},
					inputRef: (input) => (this.inputRef = input),
					endAdornment: isSafe(clearText) ? (
						<InputAdornment position="end" variant="filled" className={"xs-clear-text xs-pointer"}>
							<i className="fal fa-times" onClick={() => this.handleOnChange("", field)} />
						</InputAdornment>
					) : isNotEmpty(this.props.endText) ? (
						<InputAdornment position="end" variant="filled" className={"input-end-text"}>
							{this.props.endText}
						</InputAdornment>
					) : null
				}}
				{...field.bind({
					type,
					placeholder,
					validatingText,
					disabled,
					onChange: this.handleValidation(field),
					onBlur: () => (typeof onBlur === "function" ? onBlur(field) : null)
				})}
				inputProps={{
					min: isSafe(min) ? min : null,
					max: isSafe(max) ? max : null,
					step: isSafe(step) ? step : "any",
					tabIndex: isSafe(this.props.tabindex) ? this.props.tabindex : "",
					maxLength: isSafe(maxLength) ? maxLength : null
				}}
				label={hideLabel ? "" : labelJSX}
				multiline={multiple}
				rows={rows}
				autoFocus={autoFocus || field.autoFocus}
				//Vybral som onChange z field.bind pretoze sa to otpalovalo pri onChange ale aj ked sa renderoval input
				onChange={(event) => this.handleOnChange(event.target.value, field, onChange)}
				helperText={field && field.$error ? <span className="error-text">{field.$error}</span> : validatingText}
				onKeyPress={(event) => {
					if (
						(isSafe(submitAction) && typeof submitAction === "function" && event.which === 13) ||
						event.keyCode === 13
					) {
						submitAction(event)
					}
				}}
			/>
		)
	}
}
