import React from "react"
import {observer} from "mobx-react"
import Button from "@material-ui/core/Button"

import "./xsButton.less"

// export default observer(({id, className, text, onClick, icon = "", disabled, size = "medium"}) => {

@observer
export default class XsButton extends React.Component {
	render() {
		const {id, className, text, onClick, icon = "", size = "medium", disabled, tabindex} = this.props
		return (
			<Button id={id} className={className} onClick={onClick} disabled={disabled} size={size} tabIndex={tabindex}>
				{icon}
				<span className={isEmpty(icon) ? "" : "xs-text"}>{text}</span>
			</Button>
		)
	}
}
