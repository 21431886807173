import React, {Component} from "react"
import {Switch, Route, withRouter} from "react-router-dom"
// import {load} from "recaptcha-v3"

import Home from "../modules/login/home"
import RequestForm from "../modules/request/requestForm"
import SomPacient from "../modules/somPacient/somPacient"
import Dokumentacia from "../modules/dokumentacia/dokumentacia"
import Referencie from "../modules/referencie/referencie"
import Faq from "../modules/faq/faq"
import OrderWithoutLogin from "../modules/somPacient/orderWithoutLogin"
import SendMessageToDoctorWithoutLogin from "../modules/somPacient/sendMessageToDoctorWithoutLogin"

import Header from "../modules/header"
import Footer from "../modules/footer"

import {inject, observer} from "mobx-react"

import "../global/styles/global.less"

// import api from "../modules/actions/api"

import XsSnackbar from "../global/ui/xsSnackbar/xsSnackbar"
import XsErrorDialog from "../global/ui/xsDialog/xsErrorDialog"
import XsWarningDialog from "../global/ui/xsDialog/xsWarningDialog"
import XsInfoDialog from "../global/ui/xsDialog/xsInfoDialog"
import XsInformationDialog from "../global/ui/xsDialog/xsInformationDialog"

// import GlobalStore from "../global/store/GlobalStore"
// import RouterStore from "../global/store/RouterStore"

// import {StorageBase} from "../global/storage/storageEx"
// import {LANGUAGE} from "../global/config/constants"

@inject("routing")
@withRouter
@observer
export default class App extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		document.documentElement.lang = "sk"
		const token = new URLSearchParams(this.props.routing.location.search).get("token")

		return (
			<React.Fragment>
				<Switch>
					<Route exact path="/" component={() => <Home />} />
					<Route exact path="/request" component={() => <RequestForm />} />
					<Route
						exact
						path="/somPacient"
						component={() => (
							<React.Fragment>
								<Header />
								<SomPacient token={token} />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/dokumentacia"
						component={() => (
							<React.Fragment>
								<Header />
								<Dokumentacia />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/order"
						component={() => (
							<React.Fragment>
								<Header />
								<OrderWithoutLogin token={token} />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/sendMessage"
						component={() => (
							<React.Fragment>
								<Header />
								<SendMessageToDoctorWithoutLogin token={token} />
								<Footer />
							</React.Fragment>
						)}
					/>
					{/* <Route
						exact
						path="/certifikaty"
						component={() => (
							<React.Fragment>
								<Header />
								<Certifikaty />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/zacinameAmbee"
						component={() => (
							<React.Fragment>
								<Header />
								<ZacinameAmbee />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/financneZuctovanie"
						component={() => (
							<React.Fragment>
								<Header />
								<FinancneZuctovanie />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/servisnaZmluva"
						component={() => (
							<React.Fragment>
								<Header />
								<ServisnaZmluva />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/podmienkyPouzivania"
						component={() => (
							<React.Fragment>
								<Header />
								<PodmienkyPouzivania />
								<Footer />
							</React.Fragment>
						)}
					/> */}
					<Route
						exact
						path="/referencie"
						component={() => (
							<React.Fragment>
								<Header />
								<Referencie />
								<Footer />
							</React.Fragment>
						)}
					/>
					<Route
						exact
						path="/faq"
						component={() => (
							<React.Fragment>
								<Header />
								<Faq />
								<Footer />
							</React.Fragment>
						)}
					/>
				</Switch>
				<XsSnackbar />
				<XsErrorDialog />
				<XsWarningDialog />
				<XsInfoDialog />
				<XsInformationDialog />
			</React.Fragment>
		)
	}
}
