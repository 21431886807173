import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				name: {
					label: <FormattedMessage id="Common.label.yourName" />,
					placeholder: "Napíšte svoje meno a priezvisko",
					rules: "required"
				},
				code: {
					label: <FormattedMessage id="Common.label.doctorCode" />,
					placeholder: "A12345678",
					rules: "required"
				},
				email: {
					label: <FormattedMessage id="Common.label.emailAddress" />,
					placeholder: "meno@domena.sk",
					rules: "required"
				},
				phone: {
					label: <FormattedMessage id="Common.label.phoneNumber" />,
					placeholder: "+421 944 111 222",
					rules: "required"
				},
				ambulance: {value: "lekar"}
			}
			// fields: {
			// 	company_identifier: {
			// 		label: "IČO",
			// 		// placeholder: "Napíšte svoje meno a priezvisko",
			// 		rules: "required"
			// 	},
			// 	company_name: {
			// 		label: "Názov spoločnosti",
			// 		// placeholder: "A12345678",
			// 		rules: "required"
			// 	},
			// 	doctor_code: {
			// 		label: "Kód lekára",
			// 		rules: "required|string|between:9,9"
			// 	},
			// 	gender: {
			// 		label: "Pohlavie"
			// 	},
			// 	first_name: {
			// 		label: "Meno",
			// 		// placeholder: "+421 944 111 222",
			// 		rules: "required"
			// 	},
			// 	last_name: {
			// 		label: "Priezvisko",
			// 		// placeholder: "+421 944 111 222",
			// 		rules: "required"
			// 	},
			// 	dzp_login: {
			// 		label: "DZP eRecept login"
			// 	},
			// 	dzp_password: {
			// 		label: "DZP eRecept heslo"
			// 	},
			// 	provider_code: {
			// 		label: "Kód oddelenia",
			// 		rules: "required"
			// 	},
			// 	title_before: {
			// 		label: "Titul pred"
			// 	},
			// 	title_after: {
			// 		label: "Titul za"
			// 	},
			// 	union_login: {
			// 		label: "Union eRecept login"
			// 	},
			// 	union_password: {
			// 		label: "Union eRecept heslo"
			// 	},
			// 	vszp_login: {
			// 		label: "VŠZP eRecept login"
			// 	},
			// 	vszp_password: {
			// 		label: "VŠZP eRecept heslo"
			// 	}
			// }
		}
	}
}
