"use strict"

import {observable, action} from "mobx"
import Holidays from "date-holidays"
import moment from "moment"

const holidaySK = new Holidays("SK")

class UIStore {
	vyhodyRef = null
	dokumentacieRef = null
	cennikRef = null
	referencieRef = null
	kontaktRef = null

	scrollName = null

	@observable status = ""
	@observable loggedIn = false

	@observable isLoading = false

	@observable loading = false

	@observable dokumentacia = ""

	@action scrollTo = (scrollHeight) => {
		if (isNotEmpty(this.scrollName)) {
			this.scrollToPart(this.scrollName)
		} else {
			if (isNotEmpty(scrollHeight)) {
				window.scrollTo(0, scrollHeight)
			}
		}
	}

	@action scrollToPart(partName) {
		switch (partName) {
			case "kontakt":
				window.scrollTo(0, this.kontaktRef.offsetTop)
				break
			case "cennik":
				window.scrollTo(0, this.cennikRef.offsetTop)
				break
			case "referencie":
				window.scrollTo(0, this.referencieRef.offsetTop)
				break
			case "dokumentacia":
				window.scrollTo(0, this.dokumentacieRef.offsetTop)
				break
			case "vyhody":
				window.scrollTo(0, this.vyhodyRef.offsetTop)
				break
		}

		this.scrollName = null
	}

	actHolidayYear = moment().year()

	hdSK = holidaySK.getHolidays(this.actHolidayYear)
	dayOfYearHolidays = this.hdSK.map((x) => moment(x.date).dayOfYear())

	@observable holidays = []

	@action getDayOfYearHolidays(year) {
		if (this.actHolidayYear != year) {
			this.hdSK = holidaySK.getHolidays(year)
			this.dayOfYearHolidays = this.hdSK.map((x) => moment(x.date).dayOfYear())
			this.actHolidayYear = year
		}

		const hols = this.hdSK.map((sv) => {
			return {date: sv.date, color: "#ccd1db"}
		})

		this.holidays = hols
	}
}

var singleton = new UIStore()
export default singleton
