"use strict"

import React from "react"
import {observer} from "mobx-react"

import RouterStore from "../../global/store/RouterStore"
import UIStore from "../stores/UIStore"

@observer
export default class Certifikaty extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<section className="bg-primary-3 has-divider text-light">
					<div className="container pt-1 pb-md-0">
						<div className="row my-3">
							<div className="col">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a className="pointer" onClick={() => RouterStore.push("./")}>
												am<b>bee</b>
											</a>
										</li>
										<li className="breadcrumb-item" aria-current="page">
											<a className="pointer" onClick={() => (UIStore.dokumentacia = "")}>
												Dokumentácia
											</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Certifikáty
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h5 className="mb-4">Rešpektujeme štandardy. Vždy a bez výhrad.</h5>
								<h1 className="display-4 mb-4">Certifikácia pre ezdravie</h1>
								<p className="lead">
									Váš nový moderný ambulantný softvér musí byť v súlade s legislatívou a predpismi pre štandardnú
									komunikáciu.
								</p>
							</div>
						</div>
						<div className="row justify-content-center mb-lg-n7">
							<div className="col-xl-10 layer-1">
								<img src="public/images/ambee-calendar.png" alt="ambee Kalendáre a plánovanie" className="rounded" />
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
				</section>

				<section className="pt-4 pb-0">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-10">
								<article className="article article-portfolio">
									<p className="lead mb-3">
										V roku 2018 am<b>bee</b> absolvoval overenie a certifikáciu vo všetkých zdravotných poisťovniach. Po
										úspešnej akceptácii riešenia pokračoval testami pri overení zhody v súlade s pravidlami podľa Zákona
										153/2013 o národnom zdravotníckom informačnom systéme (NZIS).
									</p>
									<p className="lead mb-3">
										Štandardy sú veľmi dôležité. Nemusíte sa trápiť, či splníte požiadavky alebo nie. To je úloha
										vývojárov am<b>bee</b>. A vždy aj bude.
									</p>
									<div className="h3 pt-3">Overenie v zdravotnej poisťovni</div>
									<p>
										am<b>bee</b> je pripravený. Elektronické recepty a poukazy vytvárate v am<b>bee</b> riadne aj
										opakované. Systém sa postará, že ich odošle do systému príslušnej zdravotnej poisťovne podľa
										príslušnosti pacienta. Prešli sme akceptačnými testami všetkých zdravotných poisťovní:
									</p>
									<ul>
										<li>
											Všeobecná zdravotná poisťovňa, a.s.
											<span className="text-muted">(18.11.2018)</span>
										</li>
										<li>
											DÔVERA zdravotná poisťovňa, a.s.
											<span className="text-muted">(6.12.2018)</span>
										</li>
										<li>
											Union zdravotná poisťovňa, a.s.
											<span className="text-muted">(4.11.2018)</span>
										</li>
									</ul>

									<div className="h3 pt-3">Overenie zhody</div>
									<p>
										<a href="http://www.nczisk.sk" target="_blank" rel="noopener noreferrer">
											Národné centrum zdravotníckych informácií (NCZI)
										</a>
										vykonáva overenie informačných systémov pre poskytovateľov zdravotnej starostlivosti v súlade s
										platným
										<a
											href="https://www.zakonypreludi.sk/zz/2013-153"
											target="_blank"
											rel="noopener noreferrer"
											title="Otvoriť stránku s detailným znením zákona. "
										>
											Zákona 153/2013 o národnom zdravotníckom informačnom systéme
										</a>
										. NCZI testami overuje, či je informačný systém v súlade s procesnými a technickými štandardami
										zdravotníckej informatiky. Overený systém dostane certifikát a je publikovaný v
										<a href="https://www.ezdravotnictvo.sk/sk/overene-is" target="_blank" rel="noopener noreferrer">
											zozname overených informačných systémov
										</a>
										. Nájdte tam aj spoločnosť <b>SPIRE Life Solution, s.r.o.</b>, ktorá je autorom am<b>bee</b>.
									</p>
									<p>
										Štandardy zdravotníckej informatiky zabezpečujú jednotnosť, bezpečnosť a integrovateľnosť v oblasti
										informačno-komunikačných technológií v zdravotníctve. Údaje evidované v zdravotníckych informačných
										systémoch musia byť zrozumiteľné, prehľadné a preukazné a musia poskytovať pravdivé informácie o
										zaznamenaných skutočnostiach.
									</p>
									<blockquote className="bg-primary-alt">
										<div className="h3 mb-2">
											am<b>bee</b> dodržiava štandardy zdravotníckej informatiky. Bez výhrad.
										</div>
										<span className="text-small text-muted">
											– JUDr. Martin Cingel, konateľ SPIRE Life Solution, s.r.o.
										</span>
										<a href="#" className="btn btn-primary btn-sm">
											<svg className="icon" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
											<span>Tweet</span>
										</a>
									</blockquote>

									<div className="h4">
										Vytvára am<b>bee</b> elektronickú dokumentáciu?
									</div>
									<p>
										<b>Vytvárate ju vy</b>, <b>lekári a sestry</b>. am<b>bee</b> vám pri tom asistuje a zbiera dáta tak,
										aby ich bolo možné v štruktúrovanej podobe odoslať do národného zdravotníckeho informačného systému.
										Ak niečo doležité nezadáte, am<b>bee</b>
										vás upozorní. Miestami si zadanie dát vynúti.
									</p>
									<p>
										am<b>bee</b> neustále dohliada, aby boli záznamy o pacientoch a poskytnutej zdravotnej
										starostlivosti v podobe, ktorú vie bez problémov a v súlade s požiadavkami zákona odoslať ako
										elektronický záznam do
										<b>Elektronickej zdravotnej knižky</b> občana.
										<b>Elektronický záznam</b> musí jeho autor, lekár, podpísať svojim
										<a
											href="https://www.ezdravotnictvo.sk/sk//-/elektronicky-preukaz-zdravotnickeho-pracovnika-epzp-1"
											target="_blank"
											rel="noopener noreferrer"
											title="Otvoriť stránku s popisom ako získať EPZP preukaz. "
										>
											EZPZ
										</a>
										preukazom. Ak lekrá EPZP nemá, je podľa zákona povinný o tento preukaz požiadať.
									</p>

									<section className="mt-3 pt-3 pb-0 mb-3">
										<div className="container">
											<div className="row justify-content-center">
												<div className="col">
													<div className="row">
														<div className="col-md-12 col-lg-12">
															<div className="card card-body bg-primary-2 text-light hover-shadow-3d">
																<div className="d-flex justify-content-between mb-3">
																	<div className="text-small d-flex">
																		<div className="mr-2">
																			<a href="#">Nezabudnite!</a>
																		</div>
																	</div>
																	<span className="badge bg-primary text-light">
																		<svg
																			className="icon icon-sm bg-white"
																			width="24"
																			height="24"
																			viewBox="0 0 24 24"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M16.5 5C14.8905 5 13.0082 6.82463 12 8C10.9918 6.82463 9.1095 5 7.5 5C4.651 5 3 7.22218 3 10.0504C3 13.1835 6 16.5 12 20C18 16.5 21 13.25 21 10.25C21 7.42177 19.349 5 16.5 5Z"
																				fill="#212529"
																			/>
																		</svg>
																		815
																	</span>
																</div>
																<div className="d-flex">
																	<a href="#" className="btn btn-lg btn-primary btn-round flex-shrink-0">
																		<svg
																			className="icon"
																			width="24px"
																			height="24px"
																			viewBox="0 0 24 24"
																			version="1.1"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<title>Icon For Warning-2</title>
																			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																				<rect opacity="0" x="0" y="0" width="24" height="24"></rect>
																				<path
																					d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z"
																					fill="#000000"
																					opacity="0.3"
																				></path>
																				<rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"></rect>
																				<rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"></rect>
																			</g>
																		</svg>
																	</a>
																	<div className="ml-3">
																		<a href="#">
																			<h4 className="mb-1">
																				Pre využívanie elektronických služieb zdravotnej poisťovne potrebujete dohodu o
																				elektronických službách.
																			</h4>
																		</a>
																		<span className="text-small opacity-70">
																			Je dôležitá, aj keď nie ste so ZP v zmluvnom vzťahu.
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									{/* <!-- ambee TIP --> */}

									<p>Len pre poriadok uvádzame niekoľko pojmov, ktoré sú v elektronickom zdravotníctve dôležité:</p>
									<ul>
										<li>
											<b>Národný zdravotnícky informačný systém</b> je súbor zdravotníckych informačných systémov v
											správe národného centra slúžiacich na zber, spracúvanie a poskytovanie informácií v zdravotníctve
											určených na správu údajovej základne; súčasťou národného zdravotníckeho informačného systému je aj
											<a
												href="https://www.npz.sk"
												target="_blank"
												rel="noopener noreferrer"
												title="Otvoriť oficiálnu stránku národného portálu. "
											>
												Národný portál zdravia
											</a>
											.
										</li>
										<li>
											<b>Národný portál zdravia</b> je webové sídlo obsahujúce aplikácie a objektivizované informácie
											súvisiace so zdravotnou starostlivosťou, so službami súvisiacimi s poskytovaním zdravotnej
											starostlivosti a informácie o verejnom zdraví a sprostredkujúce prístup do národného
											zdravotníckeho informačného systému.
										</li>
										<li>
											<b>Elektronická zdravotná knižka</b> je súbor údajov zo zdravotnej dokumentácie osoby vedených v
											Národnom registri elektronických zdravotných knižiek v rozsahu ustanovenom týmto zákonom.
										</li>
										<li>
											<b>Pacientsky sumár</b> je časť elektronickej zdravotnej knižky obsahujúci základné údaje o
											zdravotnom stave osoby v rozsahu ustanovenom týmto zákonom pre potreby poskytovania zdravotnej
											starostlivosti.
										</li>
										<li>
											<b>Štandardy zdravotníckej informatiky</b> definujú štruktúru a formát dátových rozhraní,
											štruktúru, kvalitu, čas a spôsob evidencie zdravotníckych údajov a poskytovania zdravotníckych
											údajov.
										</li>
										<li>
											<b>Elektronický zdravotný záznam</b> je záznam zdravotníckeho pracovníka v elektronickej
											zdravotnej knižke vo forme elektronického dokumentu podpísaného zdokonaleným elektronickým
											podpisom. Elektronické zdravotné záznamy o lekárskom predpise alebo poukaze pred zápisom do
											elektronickej zdravotnej knižky prechádzajú informačným systémom zdravotnej poisťovne.
										</li>
									</ul>
								</article>
							</div>
						</div>
					</div>
				</section>

				<section className="has-divider pt-0">
					<div className="container pt-3">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<hr />
								<div className="d-flex align-items-center">
									<span className="text-small mr-1">Zdieľajte:</span>
									<div className="d-flex mx-2">
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Facebook</title>
												<path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>LinkedIn</title>
												<path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
											</svg>
										</a>
									</div>
								</div>
								<hr />
							</div>
						</div>
						<div className="row justify-content-center pt-3">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<div className="h4">
									O am<b>bee</b>
								</div>
								<p>
									am<b>bee</b> je moderný ambulantný systém pre všeobecných lekárov aj špecialistov. Vyvíjame ho spolu s
									lekármi a ich zdravotnými sestrami. Na ich názore záleží, am<b>bee</b> má pomáhať práve im asistovať
									pri spracovaní dennej agendy. Kedykoľvek a kdekoľvek.
								</p>
								<p>
									am<b>bee</b> prešiel v roku 2018 overením zhody v Národnom centre zdravotníckych informácií a
									testovali sme ho na komunikáciu so všetkými zdravotnými poisťovňami. Neustále ho rozvíjame a reagujeme
									na potreby lekárov, či legislatívne zmeny.
								</p>
							</div>
						</div>
					</div>

					<div className="divider">
						<svg
							className="bg-primary-alt"
							width="100%"
							height="100%"
							version="1.1"
							viewbox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z" fill="#ffffff"></path>
						</svg>
					</div>
				</section>
				{/* <!-- share & About ambee --> */}
			</React.Fragment>
		)
	}
}
