"use strict"

import React from "react"
import {observer} from "mobx-react"

import Uvod from "./uvod"
import Certifikaty from "./certifikaty"
import ZacinameAmbee from "./zacinameAmbee"
import FinancneZuctovanie from "./financneZuctovanie"
import ServisnaZmluva from "./servisnaZmluva"
import PodmienkyPouzivania from "./podmienkyPouzivania"

import UIStore from "../stores/UIStore"

@observer
export default class Dokumentacia extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		let scripts = [
			"../public/scripts/jquery.min.js",
			"../public/scripts/popper.min.js",
			"../public/scripts/bootstrap.js",
			"../public/scripts/moment.min.js",
			"../public/scripts/moment-business.js",
			"../public/scripts/aos.js",
			"../public/scripts/clipboard.js",
			"../public/scripts/jquery.fancybox.min.js",
			"../public/scripts/flickity.pkgd.min.js",
			"../public/scripts/ion.rangeSlider.min.js",
			"../public/scripts/isotope.pkgd.min.js",

			"../public/scripts/jarallax.min.js",
			"../public/scripts/jarallax-video.min.js",
			"../public/scripts/jarallax-element.min.js",
			"../public/scripts/jquery.countdown.min.js",
			"../public/scripts/plyr.polyfilled.min.js",
			"../public/scripts/prism.js",
			"../public/scripts/scrollMonitor.js",
			"../public/scripts/smooth-scroll.polyfills.js",
			"../public/scripts/twitterFetcher_min.js",
			"../public/scripts/typed.min.js",
			"../public/scripts/theme.js",
			"../public/scripts/controller.js",

			"../public/scripts/sweetalert.min.js",
			"../public/scripts/maine.js"
		]

		for (let i = 0; i < scripts.length; i++) {
			const script = document.createElement("script")
			script.src = scripts[i]
			script.async = false
			// script.onload = () => document.querySelector("body").classList.add("loaded")

			document.body.appendChild(script)
		}
		document.querySelector("body").classList.add("loaded")
	}

	componentWillUnmount() {
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; i++) {
			scripts[i].parentNode.removeChild(scripts[i])
		}
	}

	renderContent = () => {
		window.scrollTo(0, 0)
		switch (UIStore.dokumentacia) {
			case "cert":
				return <Certifikaty />
			case "zacinameAmbee":
				return <ZacinameAmbee />
			case "financneZuctovanie":
				return <FinancneZuctovanie />
			case "servisnaZmluva":
				return <ServisnaZmluva />
			case "podmienkyPouzivania":
				return <PodmienkyPouzivania />
			default:
				return <Uvod />
		}
	}

	render() {
		return this.renderContent()
	}
}
