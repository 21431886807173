"use strict"

import {FormattedMessage} from "react-intl"
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import fields from "./loginFields"
import bindings from "../../global/ui/globalUISchemeBindings"

import Grid from "@material-ui/core/Grid"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsLoading from "../../global/ui/xsLoading/xsLoading"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import Order from "./order"
import RequestForm from "../request/requestForm"
import ForgotPassword from "./forgotPassword"
import SendMessageToDoctor from "./sendMessageToDoctor"

import GlobalStore from "../../global/store/GlobalStore"
import PatientStore from "../stores/PatientStore"
import UIStore from "../stores/UIStore"

import LoginPacient from "./loginPacient"
import PodmienkyPouzivaniaDialog from "./podmienkyPouzivaniaDialog"

@observer
export default class Login extends React.Component {
	constructor(props) {
		super(props)

		window.scrollTo(0, 0)

		const hooks = {
			onSubmit(form) {
				if (UIStore.status == "password") {
					if (!form.$("agree").value) {
						GlobalStore.setNotificationMessage("Musíte súhlasiť s podmienkami používania")
						form.invalidate()
					}
					if (isEmpty(form.$("password").value) || isEmpty(form.$("confirmPassword").value)) {
						GlobalStore.setNotificationMessage("Heslo a Potvrdenie hesla sú povinné polia")
						form.invalidate()
					}
					if (form.$("password").value != form.$("confirmPassword").value) {
						GlobalStore.setNotificationMessage("Zadané hesla sa nezhodujú")
						form.invalidate()
					}
				}
			},
			onSuccess(form) {
				let values = form.values()

				if (UIStore.status == "password") {
					UIStore.isLoading = true

					let request = {
						password: values.password,
						as_login: true
					}

					PatientStore.newPassword(request, PatientStore.newPasswordToken)
				}

				if (UIStore.status == "login") {
					UIStore.isLoading = true
					let request = {
						login: values.email,
						password: values.password
					}

					PatientStore.login(request)
				}
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		if (isNotEmpty(props.token)) {
			PatientStore.patientActionInfo(props.token)
		}
	}

	componentDidMount() {
		let scripts = [
			"../public/scripts/jquery.min.js",
			"../public/scripts/popper.min.js",
			"../public/scripts/bootstrap.js",
			"../public/scripts/moment.min.js",
			"../public/scripts/moment-business.js",
			"../public/scripts/aos.js",
			"../public/scripts/clipboard.js",
			"../public/scripts/jquery.fancybox.min.js",
			"../public/scripts/flickity.pkgd.min.js",
			"../public/scripts/ion.rangeSlider.min.js",
			"../public/scripts/isotope.pkgd.min.js",

			"../public/scripts/jarallax.min.js",
			"../public/scripts/jarallax-video.min.js",
			"../public/scripts/jarallax-element.min.js",
			"../public/scripts/jquery.countdown.min.js",
			"../public/scripts/plyr.polyfilled.min.js",
			"../public/scripts/prism.js",
			"../public/scripts/scrollMonitor.js",
			"../public/scripts/smooth-scroll.polyfills.js",
			"../public/scripts/twitterFetcher_min.js",
			"../public/scripts/typed.min.js",
			"../public/scripts/theme.js",
			"../public/scripts/controller.js",

			"../public/scripts/sweetalert.min.js",
			"../public/scripts/maine.js"
		]

		for (let i = 0; i < scripts.length; i++) {
			const script = document.createElement("script")
			script.src = scripts[i]
			script.async = false
			// script.onload = () => document.querySelector("body").classList.add("loaded")

			document.body.appendChild(script)
		}
		document.querySelector("body").classList.add("loaded")
	}

	componentWillUnmount() {
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; i++) {
			scripts[i].parentNode.removeChild(scripts[i])
		}
	}

	renderContent = () => {
		if (UIStore.status == "password" || UIStore.status == "login") {
			return (
				<section className="bg-primary-3 has-divider text-light" style={{paddingTop: "6rem"}}>
					<div className="container pt-1 pb-md-0">
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h1 className="display-4 mb-4">Vitajte v sekcii určenej pre pacientov</h1>
								<p className="lead pb-4">
									Pomocou kontaktného formulára môžete požiadať lekára o vystavenie receptu na liek, pomôcku alebo
									dietetickú potravinu. V sekcii objednanie sa u lekára môžete objednať na konkrétny druh vyšetrenia.
									Dátum a čas objednania závisí od zverejnených ordinačných hodín daného lekára.
								</p>
								{UIStore.status == "password" ? (
									<Grid container direction="column" spacing={16}>
										<Grid item>
											<XsInput
												field={this.form.$("password")}
												type="password"
												submitAction={(e) => this.form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsInput
												field={this.form.$("confirmPassword")}
												type="password"
												submitAction={(e) => this.form.onSubmit(e)}
											/>
										</Grid>
										<Grid item className="pt-0 text-left" style={{fontSize: "12px"}}>
											Heslo musí mať minimálne 8 znakov, obsahovať aspoň jedno číslo a veľké písmeno.
										</Grid>
										<Grid item className="text-left pt-4">
											<XsCheckbox field={this.form.$("agree")} />
										</Grid>
										<Grid item className="text-left">
											Podmienky používania si môžete pozrieť{" "}
											<a onClick={() => PatientStore.open()} className="pointer" style={{textDecoration: "underline"}}>
												TU!
											</a>
										</Grid>
										<Grid item className="pt-4">
											<XsButton
												className="xs-primary"
												text={<FormattedMessage id="Common.label.login" />}
												onClick={this.form.onSubmit}
											/>
										</Grid>
									</Grid>
								) : (
									<Grid container direction="column" spacing={16}>
										<Grid item>
											<XsInput field={this.form.$("email")} submitAction={(e) => this.form.onSubmit(e)} />
										</Grid>
										<Grid item>
											<XsInput
												field={this.form.$("password")}
												type="password"
												submitAction={(e) => this.form.onSubmit(e)}
											/>
										</Grid>
										<Grid item className="pt-4">
											<XsButton
												className="xs-primary"
												text={<FormattedMessage id="Common.label.login" />}
												onClick={this.form.onSubmit}
											/>
										</Grid>
										<Grid item>
											<FormattedMessage id="Common.label.forgotPassword" />
											<span
												className="blueInfo pointer"
												onClick={() => PatientStore.openForgotPassword(this.form.$("email").value)}
											>
												{" "}
												SEM!
											</span>
										</Grid>
									</Grid>
								)}
								<p className="text-muted mt-5">
									Ak ešte nemáte prihlasovacie údaje, kontaktujte Vášho ošetrujúceho lekára, ktorý Vám na Vašu emailovú
									adresu zašle pozývací email, ktorým sa budete vediet do portálu prihlásiť.
								</p>
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
					<ForgotPassword />
				</section>
			)
		} else if (UIStore.status == "order") {
			return <Order />
		} else if (UIStore.status == "sendMessage") {
			return <SendMessageToDoctor />
		} else {
			return <RequestForm />
		}
	}

	render() {
		return (
			<React.Fragment>
				{UIStore.isLoading && <XsLoading overlay={true} />}
				{isEmpty(UIStore.status) ? <LoginPacient /> : this.renderContent()}
				<PodmienkyPouzivaniaDialog />
			</React.Fragment>
		)
	}
}
