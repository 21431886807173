import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import {Grid, Dialog} from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import renderHTML from "react-render-html"
import "./xsDialog.less"

import XsButton from "../xsButton/xsButton"

import InfoDialogStore from "../../store/InfoDialogStore"

@observer
export default class XsInfoDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		let infoObj = isSafe(InfoDialogStore.infoObj) ? InfoDialogStore.infoObj : undefined

		return (
			<Dialog
				aria-describedby="alert-dialog-description"
				open={InfoDialogStore.isOpen}
				onEscapeKeyDown={() => InfoDialogStore.close()}
				maxWidth="md"
				className="xs-info-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							{isSafe(infoObj) && isSafe(infoObj.header) && isSafe(infoObj.header.icon) ? (
								infoObj.header.icon
							) : (
								<i className="fal fa-info-circle fa-2x" />
							)}
						</div>
						<div className="xs-header-title">
							{isSafe(infoObj) && isSafe(infoObj.header) && isSafe(infoObj.header.text) ? (
								infoObj.header.text
							) : (
								<FormattedMessage id="Common.label.info" />
							)}
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content" style={{width: "600px", maxWidth: "1200px"}}>
					{isSafe(infoObj) && isSafe(infoObj.content) && typeof infoObj.content === "function"
						? infoObj.content()
						: isSafe(infoObj.content)
						? renderHTML(infoObj.content)
						: "nic"}
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="center">
						<Grid item xs={3}>
							<XsButton
								className="xs-info"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => InfoDialogStore.close()}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
