//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions, Grid} from "@material-ui/core"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsInput from "../../global/ui/xsInput/xsInput"
import PatientStore from "../stores/PatientStore"
import GlobalStore from "../../global/store/GlobalStore"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../global/ui/globalUISchemeBindings"

@observer
export default class ChangePasswordDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (form.$("password_new").value != form.$("password_confirm").value) {
					GlobalStore.setNotificationMessage("Nové heslo a potvrdenie hesla sa musia zhodovať")
					form.invalidate()
				}
			},
			onSuccess(form) {
				PatientStore.changePassword(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					password_old: {
						label: <FormattedMessage id="Common.label.oldPassword" />,
						rules: "required"
					},
					password_new: {
						label: <FormattedMessage id="Common.label.newPassword" />,
						rules: "required"
					},
					password_confirm: {
						label: <FormattedMessage id="Common.label.confirmPassword" />,
						rules: "required"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		PatientStore.forgotPasswordFormRef = this.form
	}

	render() {
		return (
			<Dialog
				id="xsChangePasswordDialog"
				open={PatientStore.isOpenChangePassword}
				onClose={() => PatientStore.closeChangePassword()}
				onEscapeKeyDown={() => PatientStore.closeChangePassword()}
				disableBackdropClick={true}
				maxWidth="sm"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-unlock-alt fa-2x"></i>
						</div>
						<div className="xs-header-title">Zmeniť heslo</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column" spacing={8}>
						<Grid item>
							<XsInput field={this.form.$("password_old")} type="password" />
						</Grid>
						<Grid item>
							<XsInput field={this.form.$("password_new")} type="password" />
						</Grid>
						<Grid item>
							<XsInput field={this.form.$("password_confirm")} type="password" />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times"></i>}
								text="Zavrieť"
								onClick={() => PatientStore.closeChangePassword()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-info xs-outline"
								icon={<i className="fal fa-unlock-alt"></i>}
								text="Odoslať"
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
