import {ApiCall} from "../../global/helpers/api"
import config from "../../global/config/settings"
// import moment from "moment"

// import {getUser} from "../../global/helpers/actions"

import UIStore from "../stores/UIStore"
import GlobalStore from "../../global/store/GlobalStore"
import PatientStore from "../stores/PatientStore"

export default {
	// login(request) {
	// 	return new ApiCall(`/login`, "POST", request, true)
	// },

	login(request) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/login`,
			method: "POST",
			body: JSON.stringify(request),
			hideError: true,
			includeToken: false
		})
	},

	// newPassword(request, token, recaptchaToken) {
	// 	return new ApiCall(`/action-consume/${token}?g-recaptcha-response=${recaptchaToken}`, "POST", request)
	// },

	newPassword(request, token, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/action-consume/${token}?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(request)
		})
	},

	// actionInfo(token, recaptchaToken) {
	// 	return new ApiCall(`/action-info/${token}?g-recaptcha-response=${recaptchaToken}`, "GET")
	// },

	actionInfo(token, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/action-info/${token}?g-recaptcha-response=${recaptchaToken}`,
			method: "GET"
		})
	},

	loadGenders() {
		return new ApiCall({
			customURL: `${config.AMBEE_PUBLIC_URL}/nais/cl/gender`,
			method: "GET"
		})
	},

	loadTitles() {
		return new ApiCall({
			customURL: `${config.AMBEE_PUBLIC_URL}/nais/cl/title`,
			method: "GET"
		})
	},

	registerDoctor(req) {
		return new ApiCall({
			customURL: `${config.AMBEE_PUBLIC_URL}/nais/jruz-register`,
			method: "POST",
			body: JSON.stringify(req)
		})
	},

	// resetPassword(email) {
	// 	return new ApiCall(`/resetpassword/${email}`, "GET")
	// },

	resetPassword(email) {
		const encodeEmail = encodeURIComponent(email)
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/resetpassword/${encodeEmail}`,
			method: "GET"
		})
	},

	changePassword(req) {
		return new ApiCall(`/changepassword`, "POST", req, true)
	},

	// loadDrugs(request) {
	// 	return new ApiCall({
	// 		customURL: `${config.CUSTOM_URL}/search-drug`,
	// 		method: "POST",
	// 		body: JSON.stringify(request)
	// 	})
	// },

	loadDrugs(request) {
		return new ApiCall(`/search-drug`, "POST", request)
	},

	loadDevices(request) {
		return new ApiCall(`/search-devices`, "POST", request)
	},

	loadDietetic(request) {
		return new ApiCall(`/search-dietetic`, "POST", request)
	},

	// loadDoctor(request) {
	// 	return new ApiCall({
	// 		customURL: `${config.CUSTOM_URL}/search-personnel`,
	// 		method: "POST",
	// 		body: JSON.stringify(request)
	// 	})
	// },

	loadDoctor(request) {
		return new ApiCall(`/search-personnel`, "POST", request)
	},

	loadDoctorUnauth(request, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/search-personnel?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(request)
		})
	},

	ziskajPodmienkyPouzivania() {
		UIStore.isLoading = true

		fetch(`${config.CUSTOM_URL}/cl/consents/ambeepat/templates/ambeepat`, {
			method: "POST"
		})
			.then((response) => response.text())
			.then((html) => {
				if (isNotEmpty(html)) {
					UIStore.isLoading = false
					PatientStore.htmlContent = html
					PatientStore.isOpen = true
				} else {
					UIStore.isLoading = false
					GlobalStore.setNotificationMessage("Nepodarilo sa načítať podmienky používania")
				}
			})
			.catch(() => {
				UIStore.isLoading = false
				GlobalStore.setNotificationMessage("Nepodarilo sa načítať podmienky používania")
			})
	},

	// logout() {
	// 	return new ApiCall({
	// 		customURL: `${config.CUSTOM_URL}/logout`,
	// 		method: "POST"
	// 	})
	// },

	logout() {
		return new ApiCall(`/logout`, "POST")
	},

	// prescDraft(request) {
	// 	return new ApiCall({
	// 		customURL: `${config.CUSTOM_URL}/prescrdraft`,
	// 		method: "POST",
	// 		body: JSON.stringify(request),
	// 		hideError: true
	// 	})
	// },

	prescDraft(request) {
		return new ApiCall(`/prescrdraft`, "POST", request, true)
	},

	eventsByDay(request) {
		return new ApiCall(`/service/eventsbyday`, "POST", request)
	},

	eventsByDayUnauth(request, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/service/eventsbyday?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(request)
		})
	},

	patientEvents(clientId, request) {
		return new ApiCall(`/entity/clients/${clientId}/events`, "POST", request)
	},

	loadSlots(providerID, request) {
		return new ApiCall(`/entity/providers/${providerID}/slots`, "POST", request)
	},

	loadSlotsUnauth(providerID, request, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/entity/providers/${providerID}/slots?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(request)
		})
	},

	getOpenningHour(resourceId) {
		return new ApiCall(`/resources/${resourceId}/oh`, "POST")
	},

	getOpenningHourUnauth(resourceId, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/resources/${resourceId}/oh?g-recaptcha-response=${recaptchaToken}`,
			method: "POST"
		})
	},

	createEvent(request) {
		return new ApiCall("/orders", "POST", request)
	},

	createEventUnauth(request, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/service/orders?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(request)
		})
	},

	loadServices(resourceID, request) {
		return new ApiCall(`/resources/${resourceID}/services`, "POST", request)
	},

	loadServicesUnauth(resourceID, request, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/resources/${resourceID}/services?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(request)
		})
	},

	sendEmail(form, recaptchaToken) {
		let values = form.values()

		const req = {
			doctor_name: values.name,
			doctor_code: values.code,
			email: values.email,
			phone: values.phone,
			provider_type: values.ambulance,
			type: "demo-request"
		}

		return new ApiCall({
			customURL: `${config.AMBEE_PUBLIC_URL}/doctor/portal/request?g-recaptcha-response=${recaptchaToken}`,
			method: "PUT",
			body: JSON.stringify(req)
		})

		// let request = []
		// let req = {
		// 	nUser_id: 47,
		// 	nId: 0,
		// 	nTaskPerson_id: 1,
		// 	vState: "A",
		// 	nPlanned_duration: 60,
		// 	nOrgunit_id: 11,
		// 	nPriority: 3,
		// 	vName: "Otázka z webu ambee.sk od " + values.name,
		// 	vDescription:
		// 		"Meno: " +
		// 		values.name +
		// 		"<br/>Kód lekára: " +
		// 		values.code +
		// 		"<br/>Email: " +
		// 		values.email +
		// 		"<br/>Telefónne číslo: " +
		// 		values.phone +
		// 		"<br/>Typ: " +
		// 		values.ambulance,
		// 	vObject_name: "releases",
		// 	nObject_id: 39,
		// 	nClassification: 200,
		// 	dDeadline: moment()
		// 		.add(5, "days")
		// 		.format("YYYY-MM-DD HH:mm:ss"),
		// 	dPlanned_start: moment()
		// 		.add(1, "days")
		// 		.format("YYYY-MM-DD HH:mm:ss"),
		// 	dPlanned_end: moment()
		// 		.add(4, "days")
		// 		.format("YYYY-MM-DD HH:mm:ss"),
		// 	nCreate_notification: 1
		// }

		// for (let property in req) {
		// 	let encodedKey = encodeURIComponent(property)
		// 	let encodedValue = encodeURIComponent(req[property])
		// 	request.push(encodedKey + "=" + encodedValue)
		// }

		// request = request.join("&")

		// fetch("https://ema.ambee.sk/api/apisp.php/sp_task_iu", {
		// 	method: "POST",
		// 	headers: {"Content-Type": "application/x-www-form-urlencoded"},
		// 	body: request
		// })
		// 	.then((resp) => (isSafe(resp) ? resp.json() : null))
		// 	.then(() => {
		// 		GlobalStore.setNotificationMessage("Požiadavka bola úspešne odoslaná")
		// 		form.reset()
		// 	})
	},

	sendMessageToDoctor(req) {
		return new ApiCall(`/entity/personnel/channel/email`, "PUT", req)
	},

	sendMessageToDoctorUnauth(req, recaptchaToken) {
		return new ApiCall({
			customURL: `${config.CUSTOM_URL}/entity/personnel/channel/email?g-recaptcha-response=${recaptchaToken}`,
			method: "PUT",
			body: JSON.stringify(req)
		})
	}
}

