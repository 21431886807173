"use strict"

import React from "react"
import {observer} from "mobx-react"

import RouterStore from "../../global/store/RouterStore"
import UIStore from "../stores/UIStore"

@observer
export default class PodmienkyPouzivania extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<section className="bg-primary-3 has-divider text-light">
					<div className="container pt-1 pb-md-0">
						<div className="row my-3">
							<div className="col">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a className="pointer" onClick={() => RouterStore.push("./")}>
												am<b>bee</b>
											</a>
										</li>
										<li className="breadcrumb-item" aria-current="page">
											<a className="pointer" onClick={() => (UIStore.dokumentacia = "")}>
												Dokumentácia
											</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Podmienky používania
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h5 className="mb-4">Rešpektujeme štandardy. Vždy a bez výhrad.</h5>
								<h1 className="display-4 mb-4">Podmienky používania</h1>
								<p className="lead">
									Majte prehľad o tom, čo a ako sa v systéme deje. Pozrite si podmienky a pravidlá, ktorých sa držíme.
								</p>
							</div>
						</div>
						<div className="row justify-content-center mb-lg-n7">
							<div className="col-xl-10 layer-1">
								<img src="public/images/ambee-security.png" alt="ambee Security" className="rounded" />
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
				</section>

				<section className="pt-4 pb-0">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-10">
								<article className="article article-portfolio">
									<p className="lead mb-3">
										Tieto podmienky používania platia pre všetky softvérové produktuy a aplikácie z rodiny am<b>bee</b>.
										Na webovej stránke www.ambee.sk nepoužívame Cookies. Obsahuje informácie, ktoré potrebujú naši
										súčasní aj noví používatelia našich produktov.
									</p>
									<div className="h3 pt-3">Podmienky používania</div>
									<p>
										Webová stránka www.ambee.sk je oficiálnou stránkou softvérového produktu am<b>bee</b>. Autorom a
										prevádzkovateľom tejto stránky je spoločnosť SPIRE Life Solution, s.r.o.,so sídlom Tallerova 4, 811
										02 Bratislava, IČO 50981226, spoločnosť zapísaná v obchodnom registri Okresného súdu Bratislava,
										oddiel Sro, vložka č. 121100/B (ďalej len {`"Spoločnosť"`}). Obsah stránky chráni zákon č. 618/2003
										Z. z. o autorskom práve a právach súvisiacich s autorským právom (autorský zákon).
									</p>
									<p>
										Spoločnosť vytvorila a zverejnila túto webovú stránku a všetky jej súčasti pre svoje potreby a
										potreby svojich zákazníkov. Použitie alebo kopírovanie obsahu, obrázkov či inej dokumentácie z tejto
										stránky je povolené iba s predchádzajúcim písomným súhlasom Spoločnosti.
									</p>
									<p>
										{`"`}am<b>bee</b>
										{`"`} je ochrannou známkou produktu Spoločnosti.
									</p>
									<p>
										Celý obsah tejto stránky je publikovaný v stave ako je, bez záruk a garancií, implicitných či
										explicitných. Spoločnosť nie je zodpovedná za priame alebo nepriame škody spôsobené prístupom k
										obsahu tejto stránky.
									</p>
									<p>
										Spoločnosť nezodpovedná za dáta, údaje alebo materiály odoslané na servery spoločnosti. Spoločnosť
										si vyhradzuje právo použiť takto zaslané dáta tak, ako uzná za vhodné bez prechádzajúceho
										upozornenie ich odosielateľa alebo majiteľa.
									</p>
									<p>
										Osobné údaje ako napríklad meno, priezisko, adresa bydliska, email či telefón budú použité v súlade
										s pravidlami pre ochranu osobných dát. Odoslaním takýchto údajov ne servery Spoločnosti ich
										odosielateľ potvrdzuje, že na takéto nakladanie s dátami má súhlasy príslušnej osoby alebo že je
										osobou, ktorej tieto osobné údaje patria. Tieto údaje použije Spoločnosť iba na prípadné
										kontaktovanie a komunikáciu s existujúcim alebo budúcim klientom.
									</p>

									<div className="h3 pt-3">Ochrana osobných údajov</div>
									<p>V tejto kapitole nájdete informácie o spracúvaní osobných údajov klientov Spoločnosti.</p>
									<p>
										Obsah tejto kapitoly slúži ako informácia o spracovaní osobných údajov klientov obchodnej
										Spoločnosti v súlade s Čl. 13 nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane
										fyzických osôb v súvislosti so spracovaním osobných údajov a o voľnom pohybe týchto údajov (ďalej
										len „Nariadenie“).
									</p>
									<p>
										Spoločnosť prostriedkami tejto stránky aktívne nezhromažďuje osobné dáta. Informácie alebo dáta,
										ktoré používateľ tejto stránky odošle na servery Spoločnosti, spracuje Spoločnosť iba pre účely
										komunikácie s danou osobou. Jej používaním vyjadrujete súhlas s ich užívaním.
									</p>
									<p>
										Za účelom efektívneho používania, podpory zákazníkov a predaja našich softvérových produktov je
										nevyhnutné spracúvať osobné údaje o jednotlivcoch, ktorí majú záujem (i) o kúpu našich služieb a
										produktov, (ii) byť informovaní o marketingových novinkách v oblasti našich služieb a vývoja našich
										produktov.
									</p>
									<p>
										Získavame len osobné údaje v rozsahu, ktorý zodpovedá účelu ich spracovania. Rozsah osobných údajov
										zahŕňa: meno, priezvisko a príslušné tituly, e-mailovú adresu a telefónne číslo, kód lekára a kód
										poskytovateľa zdravotnej starostlivosti.
									</p>
									<p>
										Prevádzkovateľom sa pre účely tejto informácie rozumie:
										<br />
										<b>SPIRE LIFE SOLUTION</b> s.r.o.
										<br />
										Tallerova 4,
										<br />
										811 02 Bratislava
										<br />
										Obchodný register: Okresný súd Bratislava, oddiel: Sro, vložka č.: 121100/B
										<br />
										<br />
										IČO: <b>50981226</b>
										<br />
										DIČ: <b>2120567658</b>
										<br />
										IČ DPH: <b>SK2120567658</b>
										<br />
										<br />
										Zodpovedná osoba: <b>Mgr. Martin Cingel</b>,<br />
										e-mail: <b>martin.cingel@spirelifesolutions.sk</b>,<br />
										Telefón: <b>+421 907 898 071</b>.
									</p>

									<div className="h3 pt-3">Prepojenie na iné stránky</div>
									<p>Wevová stránka, ktorá je prepojená s www.ambee.sk, nesmie:</p>
									<ul>
										<li>
											bez súhlasu SPIRE Life Solution s.r.o. používať logo spoločnosti alebo jej produktov publikovaných
											na tejto stránke,
										</li>
										<li>bez súhlasu SPIRE Life Solution s.r.o. kopírovať obsah www.ambee.sk,</li>
										<li>
											bez súhlasu SPIRE Life Solution s.r.o. označiť prepojenie na www.ambee.sk alebo produkty
											propagované na www.ambee.sk za schválené,
										</li>
										<li>neúplne, nepravdivo alebo klamlivo vysvetľovať vzťah prevádzkovateľa a prepojnej stránky</li>
										<li>
											opisovať alebo predpokladať akékoľvek nesprávne, neúplné, klamlivé alebo zavádzajúce informácie o
											službách, činnostiach a produktoch spoločnosti SPIRE Life Solution, s.r.o.
										</li>
									</ul>

									<div className="h3 pt-3">Revíza a zmeny obsahu</div>
									<p>
										SPIRE Life Solution, s.r.o. si vyhradzuje právo zmeny týchto pravidiel a ich obsahu bez
										predchádzajúceho upozornenia. Každý používateľ tejto stránky a jej súčastí je viazaný týmito zmenami
										a mal byt si ich preštudovať pri každej návšteve tejto stránky alebo jej súčastí.
									</p>
								</article>
							</div>
						</div>
					</div>
				</section>

				<section className="has-divider pt-0">
					<div className="container pt-3">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<hr />
								<div className="d-flex align-items-center">
									<span className="text-small mr-1">Zdieľajte:</span>
									<div className="d-flex mx-2">
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Facebook</title>
												<path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>LinkedIn</title>
												<path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
											</svg>
										</a>
									</div>
								</div>
								<hr />
							</div>
						</div>
						<div className="row justify-content-center pt-3">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<div className="h4">
									O am<b>bee</b>
								</div>
								<p>
									am<b>bee</b> je moderný ambulantný systém pre všeobecných lekárov aj špecialistov. Vyvíjame ho spolu s
									lekármi a ich zdravotnými sestrami. Na ich názore záleží, am<b>bee</b> má pomáhať práve im asistovať
									pri spracovaní dennej agendy. Kedykoľvek a kdekoľvek.
								</p>
								<p>
									am<b>bee</b> prešiel v roku 2018 overením zhody v Národnom centre zdravotníckych informácií a
									testovali sme ho na komunikáciu so všetkými zdravotnými poisťovňami. Neustále ho rozvíjame a reagujeme
									na potreby lekárov či legislatívne zmeny.
								</p>
							</div>
						</div>
					</div>

					<div className="divider">
						<svg
							className="bg-primary-alt"
							width="100%"
							height="100%"
							version="1.1"
							viewbox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z" fill="#ffffff"></path>
						</svg>
					</div>
				</section>
			</React.Fragment>
		)
	}
}
