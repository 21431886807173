"use strict"

import React from "react"
import {observer} from "mobx-react"

import RouterStore from "../../global/store/RouterStore"
import UIStore from "../stores/UIStore"

@observer
export default class ServisnaZmluva extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<section className="bg-primary-3 has-divider text-light">
					<div className="container pt-1 pb-md-0">
						<div className="row my-3">
							<div className="col">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a className="pointer" onClick={() => RouterStore.push("./")}>
												am<b>bee</b>
											</a>
										</li>
										<li className="breadcrumb-item" aria-current="page">
											<a className="pointer" onClick={() => (UIStore.dokumentacia = "")}>
												Dokumentácia
											</a>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Servisná zmluva
										</li>
									</ol>
								</nav>
							</div>
						</div>
						<div className="row justify-content-center text-center mb-6">
							<div className="col-xl-8 col-lg-9 col-md-10">
								<h5 className="mb-4">Každý deň. Podľa pravidel.</h5>
								<h1 className="display-4 mb-4">Servisná zmluva</h1>
								<p className="lead">
									Určuje postupy a procesy prevádzky informačného systému am<b>bee</b>a súvisiacich IT služieb. Chráni
									nášho zákazníka.
								</p>
							</div>
						</div>
						<div className="row justify-content-center mb-lg-n7">
							<div className="col-xl-10 layer-1">
								<img src="public/images/ambee-security.png" alt="ambee Security" className="rounded" />
							</div>
						</div>
					</div>

					<div className="divider d-none d-md-block">
						<svg
							width="100%"
							height="96px"
							viewBox="0 0 100 100"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
						</svg>
					</div>
				</section>

				<section className="pt-4 pb-0">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-10">
								<article className="article article-portfolio">
									<p className="lead mb-3">
										Naše servisná zmluva nie je vôbec krátka. Obsahuje niekoľko veľmi dôležitých kapitol, kde sa
										venujeme ochrane vašich dát, ktoré považujeme sa veľmi citlivé. Naše služby bez podpisu tejto zmluvy
										neposkytujeme. Prečítajte si, čo obsahujú jednotlivé kapitoly zmluvy.
									</p>
									<div className="h3 pt-3">Miesto poskytnutia služieb</div>
									<p>
										V tejto časti sa dohodneme a určíme, kde poskytujeme naše služby. ambee môže byť inštalované priamo
										u vás v ambulancii, na serveri na poliklnike či nemocnice, alebo v nami prevádzkovanom cloude.
										Rozhodnutie je na vás. Naše služby však poskytujeme spravidla vzdialene a preto potrebujeme prístup
										na miesta, kde máte svoje počítače a servery uložené.
									</p>
									<p>
										Cesta nášho riešiteľa na vaše pracovisko a jeho činnosti na mieste nie sú pokryté štandardným
										paušálom. Vždy ide o individuálnu dohodu tak, aby sme boli k vám čo najbližie v čase, ktorý vám
										najviac vyhovuje.
									</p>

									<div className="h3 pt-3">Ochrana informácií</div>
									<p>
										Rozumieme, že pracujete s veľmi citlivými údajomi a dátami. Ich ochrana je dôležitá. Preto servisná
										zmluva obsahuje celkom rozsiahly popis pravidiel, ktoré zmluvné strany musia dodržiavať.
									</p>
									<p>
										Zaväzujú nás aj vás zachovávať mlčanlivosť o dôverných informáciách. Tento záväzok nie je časovo
										obmedzený a trvá aj po skončení účinnosti tejto zmluvy (napr. v dôsledku odstúpenia, výpovede,
										uplynutia času a pod.). Bez vášho súhlasu informácie zo zmluvy alebo prevádzkovaných aplikácií
										nebudú publikované nikdy a nikde.
									</p>

									<div className="h3 pt-3">Práva duševného vlastníctva</div>
									<p>
										Sme autormi našich produktov a sme oprávnení k nim vykonávať majetkové práva a poskytovať úplne
										všetky služby, ktoré s prevádzkou a rozvojom aplikácií súvisia.
									</p>
									<p>
										Licencia na používanie ambee, ktorú potrebuje mať každý náš zákazník, je udelená podpisom servisnej
										zmluvu a je platná po dobu, po ktorú zákazník platí dohodnutý pevný poplatok.
									</p>

									<div className="h3 pt-3">Ochrana osobných údajov</div>
									<p>
										Zaväzujeme sa aj zmluvne, že budeme dodržiavať všetky nariadenia a predpisy o ochrane osobných
										údajov, ktoré vychádzajú zo smerníc Európskej únie aj právnych predpisov Slovenskej republiky.
									</p>
									<p>
										Naši riešitelia sú si vedomí, s akými dátami pracujú. Všetky tieto náležitosti máme ošetrené aj v
										pracovných zmluvách, aj v zmluvách so subdodávateľmi.
									</p>

									<div className="h3 pt-3">Parametre služieb</div>
									<p>
										Servisná zmluva definuje celkom presne, čo je a čo nie je predmetom služby, aké má služba parametre,
										ktoré musíme dodržať. Za veľmi dôležité považujeme označovanie incidentov podľa ich priority. Podľa
										nej ich aj riešime. Vážnejšie skôr, menej závažné alebo kozmetické čakajú dlhšie. Vyriešime všetky.
										Samozrejme.
									</p>
									<p>
										Špeciálnou kapitolou servisnej zmluvy je nastavenie poažadovaných úrovní služieb. Tu si určíme, ako
										najdlhšie nám môže trvať odstránenie incidentu podľa jeho závažnosti. Parametre sú určené v hodinách
										vo vzťahu k dobe pokrytia. Rozlišujeme aj kritické a nekritické systémy. Kritické sú tie, ktoré
										musia byť dostupné 24 hodín denne.
									</p>
									<p>
										Ambulatný systém patrí do katerógie Nekritických systémov z pohľadu času podpory a tie sú
										prevádzkované spravidla 8 hodín denne. To ale vôbec neznamená, že am<b>bee</b> nemôže použiť večer
										alebo aj o polnoci. Ak je inštalovaný v cloude, je dostupný kedykoľvek a kdekoľvek. O to sa staráme
										my. Ak by problém nastal, garantujeme opravy počas hodín pokrytia.
									</p>
									<ul>
										<li>
											<b>INC A</b> - Kritický incident nastáva vtedy, keď nefunguje aplikácia vôbec. Výpadok inej ako
											našej aplikácie nie je incident, ale nedostupnosť systému tretej strany môže mať dopad na funkcie
											am<b>bee</b>.
										</li>
										<li>
											<b>INC B</b> - Podstatná chyba nastáva vtedy, ak nie je možné pracovať na celom pracovisku.
										</li>
										<li>
											<b>INC C</b> - Bežný problém nastáva vtedy, keď nefunguje niektorá z funkcií am<b>bee</b>. Dôvody
											môžu byť rôzne, niekedy sú príčinou aj dáta. Tie vám pomôžeme opraviť.
										</li>
										<li>
											<b>INC D</b> - Kozmetický problém am<b>bee</b>. Nespôsobuje prevádzkové problémy, môže mierne
											znižovať komfort obsluhy. Tieto incidenty zbierame a po vyhodnotení zaraďujeme na spracovanie,
											optimalizáciu funkcií a ich použiteľnosti podľa pripomienok lekárov a zdravotných sestier.
										</li>
									</ul>

									<div className="h3 pt-3">Dôležité pojmy</div>
									<p>Uvádzame niekoľko pojmov, s ktorými sa pri čítaní našej servisnej zmluvy stretnete:</p>
									<ul>
										<li>
											<b>SPOC</b>, alebo aj Single Point of Contact, je jednotné miesto, cez ktoré prijímame vaše
											hlásenie o prípadných chybách alebo požiadavkách na rozšírenie funkcií našich aplikácií. Nájdete
											ho na
											<a href="https://helpdesk.ambee.sk/" title="Otvoriť stránky Help Desku ambee. ">
												helpdesk.ambee.sk
											</a>
											.
										</li>
										<li>
											<b>Hodiny pokrytia</b>, alebo aj Service Hours, je časový interval, kedy poskytujeme naše služby,
											môžu sa líšiť v rozsahu podľa uzatvorenej servisnej zmluvy, štandardná doba je od pondelka do
											piatku a od 08:00 do 16:00.
										</li>
										<li>
											<b>Odozva</b> je čas, do kedy najneskôr prevezmeme vaše hlásenie o incidente alebo požiadavky
											počas dohodnutých hodín pokrytia.
										</li>
										<li>
											<b>Neutralizácia</b> je spravidla dočasné riešenie, ktoré pri identifikovaní chyby zabráni
											opakovaniu neželanej situácie. Nie každý incident je možné neutralizovať a nie vždy je
											neutralizáciu možné vykoanť okamžite.
										</li>
										<li>
											<b>Oprávnená osoba</b> je jedna alebo viac osôb, ktoré sú uvedené v servisnej zmluve za zákazníka
											aj za dodávateľa služieb. Len tieto osoby môžu komunikovať na oficiálnej úrovni, hlásiť incidenty
											či zadávať požiadavky.
										</li>
										<li>
											<b>Človekodeň</b> je 8 hodín práce jedného človeka.
										</li>
										<li>
											<b>Pracovná hodina</b> je hodina práce, ktoré uplynie pri činnosti riešiteľa počas dohodnutých
											hodín pokrytia určených servisnou zmluvou.
										</li>
										<li>
											<b>Služba</b> je súbor aktivít a činností, ktoré vykonávame pre našich zákazníkov. Spravidla sa
											jedná o služby podpory prevádzky našich aplikácií alebo ich rozvoj.
										</li>
										<li>
											<b>Incident</b> je udalosť, ktorá nastane pre nesprávne fungovanie alebo nesprávny výstup
											aplikácie. Incidenty riešime počas dohodnutých hodín pokrytia a v časoch, ktoré určuje servisná
											zmluva.
										</li>
										<li>
											<b>Problém</b> je nesprávna funkcionalita, ktorá sa spravidla opakuje viac ako raz, alebo združuje
											viacero incidentov, ktoré majú spravidla tú istú príčinu.
										</li>
										<li>
											<b>Požiadavka</b> je štruktúrovane vyjadrená potreba Zákazníka na poskytnutie služby alebo
											vykonnanie nejakej činnosti. Požiadavky prijímame iba cez náš Help Desk a to telefonicky na
											určenom číslo alebo elektronicky cez aplikáciu
											<a href="https://helpdesk.ambee.sk/" title="Otvoriť stránky Help Desku ambee. ">
												helpdesk.ambee.sk
											</a>
											.
										</li>
									</ul>
								</article>
							</div>
						</div>
					</div>
				</section>

				<section className="has-divider pt-0">
					<div className="container pt-3">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<hr />
								<div className="d-flex align-items-center">
									<span className="text-small mr-1">Zdieľajte:</span>
									<div className="d-flex mx-2">
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Twitter</title>
												<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>Facebook</title>
												<path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
											</svg>
										</a>
										<a href="#" className="btn btn-round btn-primary mx-1">
											<svg className="icon icon-sm" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<title>LinkedIn</title>
												<path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
											</svg>
										</a>
									</div>
								</div>
								<hr />
							</div>
						</div>
						<div className="row justify-content-center pt-3">
							<div className="col-xl-7 col-lg-8 col-md-10">
								<div className="h4">
									O am<b>bee</b>
								</div>
								<p>
									am<b>bee</b> je moderný ambulantný systém pre všeobecných lekárov aj špecialistov. Vyvíjame ho spolu s
									lekármi a ich zdravotnými sestrami. Na ich názore záleží, am<b>bee</b> má pomáhať práve im asistovať
									pri spracovaní dennej agendy. Kedykoľvek a kdekoľvek.
								</p>
								<p>
									am<b>bee</b> prešiel v roku 2018 overením zhody v Národnom centre zdravotníckych informácií a
									testovali sme ho na komunikáciu so všetkými zdravotnými poisťovňami. Neustále ho rozvíjame a reagujeme
									na potreby lekárov či legislatívne zmeny.
								</p>
							</div>
						</div>
					</div>

					<div className="divider">
						<svg
							className="bg-primary-alt"
							width="100%"
							height="100%"
							version="1.1"
							viewbox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
						>
							<path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z" fill="#ffffff"></path>
						</svg>
					</div>
				</section>
			</React.Fragment>
		)
	}
}
