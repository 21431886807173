"use strict"

import {injectIntl} from "react-intl"
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../global/ui/globalUISchemeBindings"
import classnames from "classnames"
import moment from "moment"
import {load} from "recaptcha-v3"

import {getUser} from "../../global/helpers/actions"
import {Grid} from "@material-ui/core"
import {XsDateTimePickerDirect} from "../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"
import {getHour, getMinute, getDate, getMonth, getYear} from "../../global/ui/xsDateTimePicker/helpers"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../global/ui/xsInput/xsInput"

import PatientStore from "../stores/PatientStore"
import UIStore from "../stores/UIStore"
import GlobalStore from "../../global/store/GlobalStore"
import api from "../actions/api"
import settings from "../../global/config/settings"

import "./order.less"

@injectIntl
@observer
export default class OrderWithoutLogin extends React.Component {
	constructor(props) {
		super(props)

		window.scrollTo(0, 0)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				PatientStore.createOrderWithoutLogin(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					doctor: {
						label: "Napíšte jeho meno, priezvisko, prípadne adresu ambulancie",
						rules: "required"
					},
					fullname: {
						label: "Meno a priezvisko",
						rules: "required"
					},
					year: {
						label: "Rok narodenia"
					},
					email: {
						label: "E-mail",
						rules: "required"
					},
					mobile: {
						label: "Tel. číslo",
						placeholder: "+421999000000",
						rules: "required"
					},
					service: {
						label: "Druh návštevy",
						rules: "required"
					},
					note: {
						label: "Poznámka"
					},
					doctorName: {},
					resourceId: {},
					providerId: {}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		PatientStore.orderFormRef = this.form
	}

	componentDidMount() {
		UIStore.getDayOfYearHolidays(moment().year())

		let scripts = [
			"../public/scripts/jquery.min.js",
			"../public/scripts/popper.min.js",
			"../public/scripts/bootstrap.js",
			"../public/scripts/moment.min.js",
			"../public/scripts/moment-business.js",
			"../public/scripts/aos.js",
			"../public/scripts/clipboard.js",
			"../public/scripts/jquery.fancybox.min.js",
			"../public/scripts/flickity.pkgd.min.js",
			"../public/scripts/ion.rangeSlider.min.js",
			"../public/scripts/isotope.pkgd.min.js",

			"../public/scripts/jarallax.min.js",
			"../public/scripts/jarallax-video.min.js",
			"../public/scripts/jarallax-element.min.js",
			"../public/scripts/jquery.countdown.min.js",
			"../public/scripts/plyr.polyfilled.min.js",
			"../public/scripts/prism.js",
			"../public/scripts/scrollMonitor.js",
			"../public/scripts/smooth-scroll.polyfills.js",
			"../public/scripts/twitterFetcher_min.js",
			"../public/scripts/typed.min.js",
			"../public/scripts/theme.js",
			"../public/scripts/controller.js",

			"../public/scripts/sweetalert.min.js",
			"../public/scripts/maine.js"
		]

		for (let i = 0; i < scripts.length; i++) {
			const script = document.createElement("script")
			script.src = scripts[i]
			script.async = false
			// script.onload = () => document.querySelector("body").classList.add("loaded")

			document.body.appendChild(script)
		}
		document.querySelector("body").classList.add("loaded")

		//Personnnel Expertise Contract ID in query parameter
		const searchParams = new URLSearchParams(window.location.search)
		const paramValue = searchParams.get("pecId")

		if (paramValue) {
			this.loadDoctorDataWithRecaptcha({
				filters: [
					{associated_column: "personnel_expertise_contract_id", predicate: "=", values: [{id_value: paramValue}]}
				],
				row_count_full: 1
			}).then((rows) => {
				if (rows && rows.length) {
					const obj = rows.find((row) => row.personnel_expertise_contract_id === paramValue)
					if (obj) {
						this.form.$("doctor").value = obj.personnel_expertise_contract_id
						this.postActionDoctorSearch(obj)
					}
				}
			})
		}
	}

	componentWillUnmount() {
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; i++) {
			scripts[i].parentNode.removeChild(scripts[i])
		}

		PatientStore.selectedIdx = undefined
		PatientStore.selectedSlot = undefined
	}

	handleTimeClick = (selectedDateTime, hourList) => {
		let date = getDate(PatientStore.selectedDate)
		let month = getMonth(selectedDateTime)
		let year = getYear(selectedDateTime)
		let hour = hourList ? getHour(selectedDateTime) : getHour(PatientStore.selectedDate)
		let minute = hourList ? getMinute(PatientStore.selectedDate) : getMinute(selectedDateTime)

		const datetime = moment().set({date, month, year, hour, minute})

		PatientStore.selectedDate = datetime
		PatientStore.selectedIdx = undefined

		PatientStore.setSlots(
			this.form.$("providerId").value,
			this.form.$("resourceId").value,
			this.form.$("service").value
		)
	}

	loadDoctorDataWithRecaptcha = (request) => {
		return new Promise(function(resolve) {
			load(settings.SITE_KEY).then((recaptcha) => {
				recaptcha.execute("patient_search_personnel").then((recaptchaToken) => {
					api
						.loadDoctorUnauth(request, recaptchaToken)
						.call()
						.then((res) => resolve(res.rows))
				})
			})
		})
	}

	loadDoctorData = (request) => {
		return new Promise(function(resolve) {
			api
				.loadDoctor(request)
				.call()
				.then((res) => resolve(res.rows))
		})
	}

	handleEventsByDay = (dateTime, resourceId) => {
		const user = getUser()

		UIStore.getDayOfYearHolidays(moment(dateTime).year())

		if (isSafe(user)) {
			PatientStore.handleEventsByDay(dateTime, resourceId)
		} else {
			PatientStore.handleEventsByDayUnauth(dateTime, resourceId)
		}
	}

	postActionDoctorSearch = (obj) => {
		this.form.$("doctorName").value = obj.full_name
		this.form.$("resourceId").value = obj.provider_resource_id
		this.form.$("providerId").value = obj.provider_id
		this.handleEventsByDay(PatientStore.selectedDate, obj.provider_resource_id)
		if (isSafe(obj.provider_resource_id)) {
			PatientStore.getServices(obj.provider_resource_id, this.form, obj.provider_id)
		} else {
			PatientStore.setSlots(obj.provider_id, obj.provider_resource_id, this.form.$("service").value)
		}
	}

	render() {
		return (
			<section className={classnames("bg-primary-3 has-divider text-light")} id="order">
				<div className="container pb-md-0">
					<Grid container direction="column" spacing={8}>
						<Grid item>
							<span className="bold">1.</span> Vyberte si ošetrujúceho lekára, u ktorého sa chcete objednať (vpisujte
							jeho priezvisko a meno a následne voľbu potvrdíte ľavým tlačidlom myši).
						</Grid>
						<Grid item>
							<span className="bold">2.</span> Ak viete, za akým účelom sa chcete objednať, môžete si vybrať aj druh
							návštevy u lekára, na ktorú sa chcete objednať (napr. Kontrola, Preventívna prehliadka,..).
						</Grid>
						<Grid item>
							<span className="bold">3.</span> Následne si v kalendári vyberte dátum a približný čas, na ktorý sa chcete
							u lekára objednať. Systém Vám ponúkne voľné termíny, aj s časom. Ak Vám niektorý z nich vyhovuje, kliknite
							naň ľavým tlačidlom myši a následne kliknite na Objednať.
						</Grid>
						<Grid item className="pt-3">
							Lekár môže vašu požiadavku na objednanie potvrdiť, presunúť alebo zrušiť, o čom budete informovaný.
						</Grid>
					</Grid>
					<Grid container className="pt-4">
						<Grid item xs={12} className="lead">
							Krok 1:
						</Grid>
						<Grid item xs={12} className="pt-2">
							Vyberte lekára, ku ktorému sa chcete objednať
						</Grid>
						<Grid item xs={12} className="pt-3">
							<XsAutocomplete
								field={this.form.$("doctor")}
								minLengthForSearch="3"
								clearText
								specialLoadFunction={(req) =>
									isSafe(getUser()) ? this.loadDoctorData(req) : this.loadDoctorDataWithRecaptcha(req)
								}
								filterValue="personnel_expertise_contract_id"
								autoFocus={true}
								inputRenderer={(obj) => {
									return `${obj.full_name} - ${obj.provider_name} ${
										isSafe(obj.address) && isNotEmpty(obj.address.full_address) ? "/ " + obj.address.full_address : ""
									}`
								}}
								saveValue={(obj) => obj.personnel_expertise_contract_id}
								postAction={(obj) => this.postActionDoctorSearch(obj)}
								onClear={() => {
									this.form.$("resourceId").value = ""
									this.form.$("providerId").value = ""
									this.form.$("service").value = ""
									this.handleEventsByDay(PatientStore.selectedDate, "")
									PatientStore.setSlots()
									PatientStore.serviceData = []
								}}
							/>
						</Grid>
						<Grid item xs={12} className="lead pt-3">
							Krok 2:
						</Grid>
						<Grid item xs={12} className="pt-2">
							Vyberte, na aký druh návštevy lekára sa chcete objednať (napr. Kontrola, Očkovanie, Vyšetrenie,...)
						</Grid>
						<Grid item container xs={12} className="pt-2">
							<XsSearchSelect
								field={this.form.$("service")}
								items={PatientStore.serviceData}
								onChange={(serviceId) =>
									PatientStore.setSlots(this.form.$("providerId").value, this.form.$("resourceId").value, serviceId)
								}
								disabled={isEmpty(this.form.$("doctor").value)}
							/>
						</Grid>
						<Grid item xs={12} className="pt-2">
							Zadajte aspoň meno a priezvisko.
						</Grid>
						<Grid item container className="pt-2" spacing={8}>
							<Grid item xs={8} md={4}>
								<XsInput field={this.form.$("fullname")} />
							</Grid>
							<Grid item xs={4} md={2}>
								<XsInput type="number" min={1901} max={moment().year()} field={this.form.$("year")} />
							</Grid>
							<Grid item xs={6} md={3}>
								<XsInput field={this.form.$("email")} />
							</Grid>
							<Grid item xs={6} md={3}>
								<XsInput field={this.form.$("mobile")} />
							</Grid>
						</Grid>
						<Grid item xs={12} className="pt-2">
							Sem napíšte upresnenie Vašej požiadavky pre lekára.
						</Grid>
						<Grid item container className="pt-2" spacing={8}>
							<Grid item xs={12}>
								<XsInput field={this.form.$("note")} />
							</Grid>
						</Grid>
						<Grid item xs={12} md={6} container direction="column" className="pr-4 pt-4">
							<Grid item className="lead">
								Krok 3:
							</Grid>
							<Grid item className="pt-2">
								Vyberte dátum a približný čas, na ktorý sa chcete u lekára objednať. Systém vám v Kroku 4 ponúkne voľné
								termíny lekára, z ktorých si <span className="bold">musíte aspoň jeden vybrať</span>.
							</Grid>
							<Grid item className="xs-datetime-picker">
								<div className="xs-date-picker">
									<XsDateTimePickerDirect
										onMonthChange={(sDateTime) => {
											this.handleEventsByDay(sDateTime, this.form.$("resourceId").value)
										}}
										onYearChange={(sDateTime) => {
											this.handleEventsByDay(sDateTime, this.form.$("resourceId").value)
										}}
										onDayClick={(sDateTime) => {
											PatientStore.selectedDate = sDateTime
											PatientStore.selectedIdx = undefined
											PatientStore.setSlots(
												this.form.$("providerId").value,
												this.form.$("resourceId").value,
												this.form.$("service").value
											)
										}}
										onTimeClick={(sDateTime, hourList) => {
											this.handleTimeClick(sDateTime, hourList)
										}}
										// highlightDates={PatientStore.eventsByDay}
										highlightDates={UIStore.holidays}
										selected={PatientStore.selectedDate}
										// showTimeSelect={true}
										minDate={moment()}
										inline={true}
									/>
								</div>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6} direction="column" className="slots-scrollbar pt-4">
							<Grid item className="lead">
								Krok 4:
							</Grid>
							<Grid item className="pt-2">
								Vyberte jeden z voľných termínov kliknutím ľavým tlačidlom myši (zobrazujú sa najbližšie voľné termíny k
								dátumu, ktorý ste si v kalendári vybrali)
							</Grid>
							<Grid item container direction="column" className="slots mt-4" wrap="nowrap">
								{isSafe(PatientStore.emptySlots) &&
									PatientStore.emptySlots.length > 0 &&
									PatientStore.emptySlots.map((slot, idx) => {
										return (
											<Grid
												item
												container
												key={idx}
												justify="space-between"
												className={classnames("slot pointer", {selected: idx == PatientStore.selectedIdx})}
												onClick={() => {
													PatientStore.selectedDate = moment(slot.interval_from)
													PatientStore.selectedIdx = idx
													PatientStore.selectedSlot = slot
												}}
											>
												<Grid item>{moment(slot.interval_from).format("dddd, DD.MMMM")}</Grid>
												<Grid item>
													{moment(slot.interval_from).format("HH:mm")} - {moment(slot.interval_to).format("HH:mm")}
												</Grid>
											</Grid>
										)
									})}
								{isNotEmpty(this.form.$("doctor").value) && PatientStore.emptySlots.length === 0 && (
									<Grid item className="slot">
										Pre Vami zvolený dátum nemá lekár dostupný žiadny termín. Skúste zvoliť iný dátum.
									</Grid>
								)}
								{isEmpty(this.form.$("doctor").value) && (
									<Grid item className="slot">
										Pre poskytnutie voľných termínov je potrebné vybrať lekára
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid container className="mt-4">
						<Grid item xs={12}>
							<XsButton
								className={classnames({
									"xs-primary": isSafe(PatientStore.selectedIdx),
									"xs-default": isNotSafe(PatientStore.selectedIdx)
								})}
								text="Objednať"
								// disabled={isEmpty(this.form.$("doctor").value)}
								onClick={(e) =>
									isNotSafe(PatientStore.selectedIdx)
										? GlobalStore.setNotificationMessage("Musíte si zvoliť jeden z voľných termínov v Kroku 4")
										: this.form.onSubmit(e)
								}
							/>
						</Grid>
					</Grid>
				</div>
				<div className="divider d-none d-md-block">
					<svg
						width="100%"
						height="96px"
						viewBox="0 0 100 100"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
					>
						<path d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"></path>
					</svg>
				</div>
			</section>
		)
	}
}

