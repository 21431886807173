import React, {Component} from "react"

import "./xsTableServerWithFilters.less"
import XsTableServerFilters from "./xsTableServerFilters/xsTableServerFilters"
import XsTableServer from "../xsTableServer/xsTableServer"

class XsTableServerWithFilters extends Component {
	constructor(props) {
		super(props)

		// nastavenie defaultnych filtrov:
		let filterValue = []
		if (isSafe(this.props.config.columnDefs)) {
			Object.keys(this.props.config.columnDefs).forEach((column) => {
				const columnDef = this.props.config.columnDefs[column]
				const dbColumnName = columnDef.dbName
				if (isSafe(columnDef.filter) && isNotEmpty(columnDef.filter.defaultValue)) {
					filterValue.push({
						associated_column: dbColumnName,
						values: [{id_value: columnDef.filter.defaultValue}]
					})
					// TODO: nastavit aj do actualFilters => nasledne optimalizacia v TODO v xsTableServerFilters
				}
			})
		}

		// nastavenie externych filtrov mimo stlpcov v tabulke
		if (isSafe(this.props.config.filterValue)) {
			this.props.config.filterValue.forEach((filter) => {
				filterValue.push(filter)
			})
		}

		if (isSafe(this.props.specFilter) && typeof this.props.specFilter === "function") {
			if (this.props.specFilter().length > 0) {
				this.props.specFilter().forEach((filter) => {
					filterValue.push(filter)
				})
			}
		}

		this.state = {
			apiFilter: isSafe(filterValue) && filterValue.length > 0 ? filterValue : null,
			actualFilters: {}
		}
	}

	// apiFilter musi vyzerat takto:
	// [{
	//   associated_column: "nazov_stlpca_podla_ktoreho_sa_filtruje",
	//   values: [{ id_value: hodnota_filtra }]
	// }]

	handleChangeFilters = (filters) => {
		let stateApiFilter = this.state.apiFilter

		const filterKey = Object.keys(filters)[0]

		// ak uz je aplikovany filter na tento kluc, zmenim hodnotu
		if (isSafe(stateApiFilter) && stateApiFilter.filter((x) => x.associated_column === filterKey).length === 1) {
			stateApiFilter.filter((x) => x.associated_column === filterKey)[0].values[0].id_value = filters[filterKey]
		} else {
			// ak uz je nejaky iny filter aplikovany
			if (isSafe(stateApiFilter)) {
				stateApiFilter.push({
					associated_column: filterKey,
					values: [{id_value: filters[filterKey]}]
				})
			} else {
				// inak, ak je null na zaciatku
				stateApiFilter = [
					{
						associated_column: filterKey,
						values: [{id_value: filters[filterKey]}]
					}
				]
			}
		}

		const actFilters = Object.assign(this.state.actualFilters, filters)

		logger("stateApiFilter", stateApiFilter)
		this.setState({
			actualFilters: actFilters,
			apiFilter: stateApiFilter
		})
	}

	render() {
		return (
			<div className="xs-table-with-filters">
				<XsTableServerFilters
					config={this.props.config}
					onChange={(filter) => this.handleChangeFilters(filter)}
					defaultFilter={this.state.actualFilters}
				/>

				<XsTableServer uri={this.props.uri} config={this.props.config} filterValue={this.state.apiFilter} />
			</div>
		)
	}
}

export default XsTableServerWithFilters
