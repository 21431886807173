//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import CancelIcon from "@material-ui/icons/Clear"

import XsButton from "../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

import XsTableServerWithFilters from "../xsTableServerWithFilters/xsTableServerWithFilters"

@observer
export default class AutocompleteModal extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsAutocompleteModal"
				open={this.props.isOpen}
				onClose={() => this.props.onClose()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				fullWidth={true}
				className="nx-modal-base"
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="nx-header">
						<div className="nx-title">
							{/* <FormattedMessage id="Patient.generics.dialog.headerMessage" />*/}
							{this.props.modalTitle}
						</div>
						<XsIconButton className="xs-close-btn" icon={<DeleteIcon onClick={() => this.props.onClose()} />} />
					</div>
				</DialogTitle>
				<DialogContent>
					<XsTableServerWithFilters
						uri={this.props.uri}
						specFilter={isSafe(this.props.specFilter) ? this.props.specFilter : null}
						// obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement + " | " + (obj.patient_payment ? obj.patient_payment + " | " : "") + (obj.insurer_payment ? obj.insurer_payment + " | " : "") + obj.atc + " | " + obj.active_ingredient}
						config={this.props.config}
					/>
				</DialogContent>
				<DialogActions>
					<div className="xs-button">
						<XsButton
							className="xs-danger xs-outline"
							icon={<CancelIcon />}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => this.props.onClose()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
