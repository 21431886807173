"use strict"

import {observable, action} from "mobx"

class ErrorStore {
	@observable isOpen = false
	@observable status = ""
	@observable statusText = ""
	@observable response = undefined
	@observable customErrorMessage = ""

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		// this.status = ""
		// this.statusText = ""
		// this.response = undefined
	}
}

var singleton = new ErrorStore()
export default singleton
