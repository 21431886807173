//@flow
import React from "react"
import {observer} from "mobx-react"
// import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions, Grid} from "@material-ui/core"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsInput from "../../global/ui/xsInput/xsInput"
import PatientStore from "../stores/PatientStore"
import GlobalStore from "../../global/store/GlobalStore"

import {isEmailValid} from "../../global/helpers/functions"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../global/ui/globalUISchemeBindings"

@observer
export default class ForgotPassword extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (!isEmailValid(form.$("email").value)) {
					GlobalStore.setNotificationMessage("Zadajte validní email!")
					form.$("email").invalidate()
				}
			},
			onSuccess(form) {
				PatientStore.sendNewPassword(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					email: {
						label: " ",
						rules: "required"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		PatientStore.forgotPasswordFormRef = this.form
	}

	render() {
		return (
			<Dialog
				id="xsInvitePatient"
				open={PatientStore.isOpenForgotPassword}
				onClose={() => PatientStore.closeForgotPassword()}
				onEscapeKeyDown={() => PatientStore.closeForgotPassword()}
				disableBackdropClick={true}
				maxWidth="sm"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-unlock fa-2x"></i>
						</div>
						<div className="xs-header-title">Zabudnuté heslo</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column" spacing={8}>
						<Grid item>Zadajte Váš email na ktorý Vám budú zaslané nové prihlasovacie údaje.</Grid>
						<Grid item>
							<XsInput field={this.form.$("email")} hideLabel />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times"></i>}
								text="Zavrieť"
								onClick={() => PatientStore.closeForgotPassword()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-info xs-outline"
								icon={<i className="fal fa-unlock"></i>}
								text="Odoslať"
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
